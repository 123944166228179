/* eslint-disable no-unused-vars */

import { useState, useEffect, useRef } from 'react'
import { TextureLoader, MeshBasicMaterial, DoubleSide } from 'three'
// import texture1 from './textures/tb__32x32px_introwhite_01.png'
// import texture2 from './textures/tb__32x32px_introwhite_02.png'
// import texture3 from './textures/tb__32x32px_introwhite_03.png'
// import texture4 from './textures/tb__32x32px_introwhite_04.png'
// import texture5 from './textures/tb__32x32px_introwhite_05.png'
// import texture6 from './textures/tb__32x32px_introwhite_06.png'
// import texture7 from './textures/tb__32x32px_introwhite_07.png'
// import texture8 from './textures/tb__32x32px_introwhite_08.png'
// import texture9 from './textures/tb__32x32px_introwhite_09.png'
// import texture10 from './textures/tb__32x32px_introwhite_10.png'



// import textBlockDark2 from './textures/Text_block-02.png'
// import textBlockDark3 from './textures/Text_block-03.png'
// import textBlockDark4 from './textures/Text_block-04.png'
// import textBlockDark5 from './textures/Text_block-05.png'
// import textBlockDark6 from './textures/Text_block-06.png'

// import textBlockLight7 from './textures/Text_block-07.png'
// import textBlockLight8 from './textures/Text_block-08.png'
// import textBlockLight9 from './textures/Text_block-09.png'
// import textBlockLight10 from './textures/Text_block-10.png'
// import textBlockLight11 from './textures/Text_block-11.png'

// import textV1_1 from './textures/Text_blocks_v1-18.png'
// import textV1_2 from './textures/Text_blocks_v1-19.png'
// import textV1_3 from './textures/Text_blocks_v1-20.png'
// import textV1_4 from './textures/Text_blocks_v1-21.png'
// import textV1_5 from './textures/Text_blocks_v1-22.png'
// import textV1_6 from './textures/Text_blocks_v1-24.png'
// import textV1_7 from './textures/Text_blocks_v1-25.png'
// import textV1_8 from './textures/Text_blocks_v1-26.png'
// import textV1_9 from './textures/Text_blocks_v1-28.png'
// import textV1_10 from './textures/Text_blocks_v1-29.png'

import milestone from '../images/milestone.png'
import milestoneHovered from '../images/milestoneHovered.png'



// const textTextureFiles = [texture1, texture2, texture3, texture4, texture5, texture6, texture7, texture8, texture9, texture10]
// const textTextureFiles = [texture5, texture5, texture5, texture5, texture5, texture5, texture5, texture5, texture5, texture5]

// const darkTextureFiles = [textBlockDark2, textBlockDark3, textBlockDark4, textBlockDark5, textBlockDark6]

// const lightTextureFiles = [textBlockLight7, textBlockLight8, textBlockLight9, textBlockLight10, textBlockLight11]



// const v1Textures = [textV1_1, textV1_2, textV1_3, textV1_4, textV1_5, textV1_6, textV1_7, textV1_8, textV1_9, textV1_10]

import shape1 from './textures/shape_1.png'
import shape2 from './textures/shape_2.png'
import shape3 from './textures/shape_3.png'
import shape4 from './textures/shape_4.png'
import shape5 from './textures/shape_5.png'
import shape6 from './textures/shape_6.png'
import shape7 from './textures/shape_7.png'
import shape8 from './textures/shape_8.png'
import shape9 from './textures/shape_9.png'
import shape10 from './textures/shape_10.png'
import shape11 from './textures/shape_11.png'
import shape12 from './textures/shape_12.png'
import shape13 from './textures/shape_13.png'
import shape14 from './textures/shape_14.png'
import shape15 from './textures/shape_15.png'
import shape16 from './textures/shape_16.png'
import shape17 from './textures/shape_17.png'
import shape18 from './textures/shape_18.png'
import shape19 from './textures/shape_19.png'
import shape20 from './textures/shape_20.png'
import shape21 from './textures/shape_21.png'
import shape22 from './textures/shape_22.png'
import shape23 from './textures/shape_23.png'
import shape24 from './textures/shape_24.png'
import shape25 from './textures/shape_25.png'
import shape26 from './textures/shape_26.png'
import shape27 from './textures/shape_27.png'
import shape28  from './textures/shape_28.png'
import shape29 from './textures/shape_29.png'
import shape30 from './textures/shape_30.png'
import shape31 from './textures/shape_31.png'
import shape33 from './textures/shape_32.png'
import shape32 from './textures/shape_33.png'
import shape34 from './textures/shape_34.png'
import shape35 from './textures/shape_35.png'

const shapes = [
  shape1, shape2, shape3, shape4, shape5, shape6, shape7, shape8, shape9, shape10,
  shape11, shape12, shape13, shape14, shape15, shape16, shape17, shape18, shape19, shape20,
  shape21, shape22, shape23, shape24, shape25, shape26, shape27, shape28, shape29, shape30,
  shape31, shape32, shape33, shape34, shape35,

]

const textureFiles = shapes

export const uniqueTextureCount = textureFiles.length


export default function useTextureMaterials() {
  const [materials, setMaterials] = useState([])
  const requestedMaterials = useRef([])
  useEffect(() => {

    textureFiles.forEach(textureFile => {
      if (requestedMaterials.current.length >= textureFiles.length) {
        return
      }
      const m = new MeshBasicMaterial({ color: 0xffffff,
        side: DoubleSide,
        transparent: true,
        depthTest: false,
        opacity: 0.8, //0.85
      })
      requestedMaterials.current.push(m)
      new TextureLoader().load(textureFile, texture => {
        m.map = texture
        setMaterials(mats => [...mats, m])
      })
    })
  }, [materials.length])

  return materials
}


const namedMaterials = {
  milestone,
  milestoneHovered
}

export function useNamedMaterials() {
  const [materials, setMaterials] = useState({})
  const requestedMaterials = useRef({})
  useEffect(() => {

    Object.keys(namedMaterials).forEach(name => {
      const textureFile = namedMaterials[name]
      if (Object.keys(requestedMaterials.current).length >= Object.keys(namedMaterials).length) {
        return
      }
      const m = new MeshBasicMaterial({ color: 0xffffff,
        side: DoubleSide,
        transparent: true,
      })
      requestedMaterials.current[name] = m
      new TextureLoader().load(textureFile, texture => {
        m.map = texture
        setMaterials(mats => ({...mats, [name]: m}))
      })
    })
  }, [materials.length])

  return materials
}
