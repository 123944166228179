import { TextGeometry, Mesh, MeshBasicMaterial, Font } from 'three';
import { BufferGeometryUtils } from './BufferGeometryUtils'

import typeface from './fonts3d/Damien_Display_Medium_Regular_just_necessary_characters.json'
// import typeface from './fonts3d/Damien_Display_Medium_Regular.json'
const font = new Font(typeface)

const TitleMaterial = new MeshBasicMaterial({
  color: 0xff0000
})

function getGeometries(lines) {
  return lines.map((line, lineIndex) => {
    const fontSize = 140
    const geom = new TextGeometry( line.toUpperCase(), {
      font: font,
      size: fontSize,
      height: 5,
      curveSegments: 12,
      bevelEnabled: false,
    })
    geom.computeBoundingBox()
    const width = geom.boundingBox.max.x - geom.boundingBox.min.x
    geom.translate(-width/2, -(lineIndex - lines.length / 2 + 0.5) * (fontSize * 1.3), 0)

    return geom
  })
}

const asianLines = [ 'Anti-Asian', '' ]
const decodingLines = [ 'Decoding', '' ]
const hateLines = [ '', 'Hate' ]

const asianGeometries = getGeometries(asianLines)
const decodingGeometries = getGeometries(decodingLines)
const hateGeometries = getGeometries(hateLines)

const asianMerged = BufferGeometryUtils.mergeBufferGeometries(asianGeometries)
const decodingMerged = BufferGeometryUtils.mergeBufferGeometries(decodingGeometries)
const hateMerged = BufferGeometryUtils.mergeBufferGeometries(hateGeometries)

const AsianTitleMesh = new Mesh(asianMerged, TitleMaterial)
const DecodingTitleMesh = new Mesh(decodingMerged, TitleMaterial)
const HateTitleMesh = new Mesh(hateMerged, TitleMaterial)

export { AsianTitleMesh, DecodingTitleMesh, HateTitleMesh }

