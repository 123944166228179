// const title = encodeURIComponent('title');
//encodeURIComponent(process.env.REACT_APP_PUBLIC_URL);
import "./Share.scss";
import ic_facebook from "../../images/ic_facebook.svg";
import ic_twitter from "../../images/ic_twitter.svg";
import ic_email from "../../images/ic_email.svg";

const url = "https://decodinghate.taaf.org/";
const encodedUrl = encodeURIComponent(url);
const text =
  "Decoding Hate is an interactive data visualization on the lived experience of anti-Asian hate, based on thousands of bias incidents and hate crimes reported in the news and on Twitter.";
const encodedText = encodeURIComponent(text);
const emailBody = encodeURIComponent(`${text} ${url}`);
const emailSubject = encodeURIComponent("Decoding Hate");
const shareItems = [
  {
    id: "Twitter",
    icon: ic_twitter,
    url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`,
  },
  {
    id: "Facebook",
    icon: ic_facebook,
    url: `https://www.facebook.com/sharer.php?u=${encodedUrl}`,
  },
  {
    id: "Email",
    icon: ic_email,
    url: `mailto:?body=${emailBody}&subject=${emailSubject}`,
  },
];

export default function Share() {
  return (
    <div className="share">
      {shareItems.map((s) => (
        <div key={s.id} className="service">
          <a href={s.url} target="_blank" rel="noopener noreferrer">
            <img src={s.icon} alt={s.id} />
          </a>
        </div>
      ))}
    </div>
  );
}
