import Share from "./About/Share";
import "./Footer.scss";
import logo from "../images/stopaapihate-logo.png"

export default function Footer(props) {
  return (
    <div className="footer">
      <div className="callToAction">
      <a
            href="https://stopaapihate.org/"
            target="_blank"
            rel="noreferrer"
          >
        <img alt="stopaapihate-logo" className="logo" src={logo}/>
        </a>
        <p className="footer-cta">
          To report a hate incident, <br/>go to{" "}
          <a
            href="https://stopaapihate.org/"
            target="_blank"
            rel="noreferrer"
          >
            stopaapihate.org
          </a>
        </p>
        <Share />
      </div>

      <br />
      <br />
      <div className="footer-bottom">
        <div className="left">
          <a href="http://www.taaf.org">
            © {new Date().getFullYear()} The Asian American Foundation
            <span className="hideOnNarrow"> • </span>
          </a>
          <div>
            <a
              href="https://www.pitchinteractive.com/"
              target="_blank"
              rel="noreferrer"
            >
              Site by
            </a>
            <a
              href="https://www.pitchinteractive.com/"
              target="_blank"
              rel="noreferrer"
              className="redHover redUnderline"
            >
              Pitch Interactive Inc
            </a>
          </div>
        </div>
        <div className="right">
          <div>
            <a
              href="https://www.taaf.org/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
              className="redHover redUnderline"
            >
              Terms &amp; Conditions
            </a>
          </div>
          <div>
            <a
              href="https://www.taaf.org/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="redHover redUnderline"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
