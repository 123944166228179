import React, { useMemo, useRef, useState, useEffect } from 'react'
import { scaleLinear, scaleUtc } from 'd3-scale'
import { dispatch } from 'd3-dispatch'
import throttle from 'lodash.throttle';

import './Minimap.scss'

export const minimapUpdater = dispatch('update')
const width = 150
const height = 250
const margins = { top: 0, left: 0, right: 0, bottom: 0 }
const xScale = scaleLinear()
const yScale = scaleUtc()
export default function Minimap(props) {
  const [ timelinePercentageOffset, setTimelinePercentageOffset] = useState(0)
  const { data, xDayScale, yTimeScale, timelineHeight, positionFromMinimap } = props

  useEffect(() => {
    minimapUpdater.on('update', throttle(
      offset => {
        if(offset !== timelinePercentageOffset) {
          setTimelinePercentageOffset(offset);
        }
      },
      75,
      {
        leading: true,
        trailing: true,
      }
    ))
  }, [timelinePercentageOffset])

  const svgRef = useRef()

  const timelineScreenHeight = timelineHeight / window.innerHeight
  const windowHeight = height / timelineScreenHeight
  const windowY = Math.min(height - windowHeight, timelinePercentageOffset * height)
  const windowPadding = width * 0.2
  const windowWidth = width - windowPadding
  const windowRect = <rect stroke='#fff' y={windowY} x={0} fill='none' height={windowHeight} width={windowWidth} />

  const rows = useMemo(() => {
    const inBetweenPadding = 2
    xScale
      .domain(xDayScale.domain())
      .range([0, width / 2 - inBetweenPadding / 2])

    yScale
      .domain(yTimeScale.domain())
      .range([0, height])

    let rowHeight = 1
    if (data.length) {
      const oneDay = 1000 * 60 * 60 * 24 // close enough
      rowHeight = yScale(data[0].date.valueOf() + oneDay) - yScale(data[0].date)
    }
    return data.map(({date, valueMap}, dateIndex) => {
      const y = yScale(date)
      const tweetValue = valueMap.has('tweet') ? valueMap.get('tweet').length : 0
      const mediaValue = valueMap.has('media') ? valueMap.get('media').length : 0
      const tweetSize = xScale(tweetValue)
      const mediaSize = xScale(mediaValue)
      return (
        <g key={dateIndex} transform={`translate(0, ${y})`}>
          <rect fill='#fff' height={rowHeight} width={tweetSize} x={width / 2 + inBetweenPadding / 2 - windowPadding} />
          <rect fill='#fff' height={rowHeight} width={mediaSize} x={width / 2 - inBetweenPadding / 2 - mediaSize - windowPadding} />
        </g>
      )
    })
  }, [data, windowPadding, xDayScale, yTimeScale])

  const scrollWindow = (event) => {
    const { clientY } = event
    if (!svgRef.current) {
      return
    }
    const svgPosition = svgRef.current.getBoundingClientRect()
    const y = clientY - windowHeight / 2
    const svgYPercent = Math.max(0, Math.min(1, (y - svgPosition.y) / svgPosition.height))
    positionFromMinimap(svgYPercent)
  }
  const mouseMove = (e) => {
    scrollWindow(e)
  }

  const mouseUp = () => {
    document.removeEventListener('mousemove', mouseMove)
    document.removeEventListener('mouseup', mouseUp)
  }

  const mouseDownMinimap = (e) => {
    scrollWindow(e)
    document.addEventListener('mousemove', mouseMove)
    document.addEventListener('mouseup', mouseUp)
  }
  return (
    <svg width={windowWidth} ref={svgRef} height={height} className='Minimap' onMouseDown={mouseDownMinimap}>
      <g transform={`translate(${margins.left}, ${margins.top})`}>
        <g>{rows}</g>
        {windowRect}
      </g>
    </svg>
  )
}
