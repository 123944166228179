import {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";
import { Vector3, Object3D, BufferGeometry, Color, Euler } from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import TWEEN from "@tweenjs/tween.js";
import { scaleLinear, scaleUtc } from "d3-scale";
import { extent, group, max } from "d3-array";
import classnames from "classnames";
import { useInView } from "react-intersection-observer";
import useFullscreen from "@rooks/use-fullscreen";
import { dispatch } from "d3-dispatch";
import throttle from "lodash.throttle";

import generateTextureCanvas, { closeImageSize } from "./generateTextureCanvas";
import {
  AsianTitleMesh,
  DecodingTitleMesh,
  HateTitleMesh,
} from "./TitleTextGeometry";
import { MeshSurfaceSampler } from "./MeshSurfaceSampler";
import Header from "./Header";
import Intro from "./Intro";
import Footer from "./Footer";
import Minimap, { minimapUpdater } from "./Minimap";
// import Navigation from './Navigation'
import useTextureMaterials, {
  uniqueTextureCount,
  useNamedMaterials,
} from "./useTextureMaterials";

import twitterImagePlaceholder from "../images/birds/000000.svg";
import sharrow from "../images/sharrow.svg";
import cardClose from "../images/card-close.svg";
import previousArrow from '../images/previousArrow.svg'
import nextArrow from '../images/nextArrow.svg'
import milestoneData from "../milestones.csv.js";

import "./styles.scss";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const colors = [
  "#ff3c14",
  "#0086aa",
  "#977500",
  "#bfd3dd",
  "#e4dec5",
  "#00933f",
  "#c3346c",
  "#faf984",
  "#91ffdd",
  "#ff8a72",
];

const threeWhite = new Color(0xffffff);
const threeColors = colors.map((color) => new Color(color));

const aSampler = new MeshSurfaceSampler(AsianTitleMesh);
aSampler.build();
const dSampler = new MeshSurfaceSampler(DecodingTitleMesh);
dSampler.build();
const hSampler = new MeshSurfaceSampler(HateTitleMesh);
hSampler.build();

const xDayIndexScale = scaleLinear();
const yTimeScale = scaleUtc();
const delayScale = scaleUtc();

let timelineYOffset = 0;
const twoPi = Math.PI * 2;

const timelineLabelProgress = { t: 0 };
let timelineLabelTween = null;
// boolean to disable automatic intro step updating, click to update intro when disabled
const devAutoplayIntro = true; // disable to click through intro instead of setTimeout
export const autoplayIntro =
  process.env.NODE_ENV === "development" ? devAutoplayIntro : true; // don't edit, we always want autoplay in deployment

// show the intro in development mode or not
const devSkipIntro = false; // enable to skip intro
const skipIntro = process.env.NODE_ENV === "development" ? devSkipIntro : false; // don't edit this, so intro is never skippedin deployment

const pMap = (value, istart, istop, ostart, ostop, clamp) => {
  let output =
    ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
  const max = Math.max(ostart, ostop);
  const min = Math.min(ostart, ostop);
  if (clamp) {
    output = Math.min(max, Math.max(min, output));
  }
  return output;
};

const timelineAttributes = [
  {
    field: "incident_type",
    label: "Incident Type",
    color: "#01933F",
  },
  {
    field: "incident_location_type",
    label: "Location",
    color: "#0285AA",
  },
  {
    field: "incident_covid_related",
    label: "Covid Related",
    color: "#FF3C14",
  },
  {
    field: "victim_gender",
    label: "Gender",
    color: "#977501",
  },
  // {
  //   field: 'incident_visualization_precedence',
  // }
  // {
  //   field: 'victim_group'
  // },
];

const timelineAttributeValuesToHide = {
  incident_covid_related: [],
  incident_type: ["Unknown"],
  incident_location_type: [],
  victim_gender: [],
};
const hardcodedColors = {
  "Covid Related": "#ff3c14",
  Harassment: "#00933f",
  Assault: "#977500",
  Shunning: "#bfd3dd",
  "Property-related": "#c3346c",
  "Go back to": "#0086aa",
  tweet: "#1DA1F2",
  media: "#f2c23a",
  Unknown: "#ffffff",
};

const threeHardcodedColors = Object.keys(hardcodedColors).reduce(
  (colors, key) => ({
    ...colors,
    [key]: new Color(hardcodedColors[key]),
  }),
  {}
);
const getSpecificCard = (groups, dataRow) => {
  let foundItem = null
  groups.forEach((group, groupIndex) => {
    group.forEach((item, itemIndex) => {
      if (item.userData.dataRow === dataRow) {
        foundItem = {
          instanceIndex: groupIndex,
          instanceId: itemIndex,
          dataIndex: item.userData.dataIndex,
        }
      }
    })
  })
  return foundItem
}

const getRandomCard = (groups, itemTest) => {
  let itemFoundInGroup = null;
  let bucketRetries = groups.length;
  while (!itemFoundInGroup && bucketRetries--) {
    const randomGroupIndex = Math.floor(Math.random() * groups.length);
    const items = groups[randomGroupIndex];
    const matchingIndexes = [];
    items.forEach((item, index) => {
      if (itemTest(item)) {
        matchingIndexes.push(index);
      }
    });
    if (matchingIndexes.length) {
      const randomIndex =
        matchingIndexes[Math.floor(Math.random() * matchingIndexes.length)];
      const item = items[randomIndex];
      itemFoundInGroup = {
        instanceIndex: randomGroupIndex,
        instanceId: randomIndex,
        dataIndex: item.userData.dataIndex,
      };
    }
  }
  if (!itemFoundInGroup) {
    console.log(itemTest, "found no items in ", groups);
  }
  return itemFoundInGroup;
};

const breakpoint = 640;

const insightsItemScale = 4;
const timelineItemScale = window.innerWidth <= breakpoint ? 1.5 : 2.75;

const toggleMousePointerHand = (enabled) => {
  window.document.body.style.cursor = enabled ? "pointer" : "default";
};

const milestoneUpdater = dispatch("update");
let prevMilestoneIndex = 0;

export default function ThreeScene(props) {
  const container = useRef({});
  const { data } = props;
  const points = useRef([]);
  const timelineLabelRefs = useRef([]);
  const milestoneRefs = useRef([]);
  const [monthLabelData, setMonthLabelData] = useState([]);
  const [introStepIndex, setIntroStepIndex] = useState(skipIntro ? null : 0);
  const [stepIndex, setStepIndex] = useState(-1);
  const [positionIndex, setPositionIndex] = useState(0);
  const [positionOverrides, setPositionOverrides] = useState({});
  const instancedMeshGroups = useRef(
    Array.from({ length: uniqueTextureCount }).map((d) => [])
  );
  const [selectedTimelineAttributeIndex, setSelectedTimelineAttributeIndex] =
    useState(
      timelineAttributes.findIndex((d) => d.field === "incident_covid_related")
    );
  const [timelineAttributeData, setTimelineAttributeData] = useState(null);
  const [timelineFieldValueColors, setTimelineFieldValueColors] =
    useState(null);
  const [dataByDayAndType, setDataByDayAndType] = useState([]);
  const textTextureMaterials = useTextureMaterials();
  const namedMaterials = useNamedMaterials();
  const [scrolledToTimeline, setScrolledToTimeline] = useState(false);
  const [timelineColumnHeaders, setTimelineColumnHeaders] = useState([]);
  const [timelineYear, setTimelineYear] = useState(2020);
  const timelineColumnHeaderGroupRef = useRef();
  const [milestoneTextures, setMilestoneTextures] = useState([]);
  const [hoveredMilestoneIndex, setHoveredMilestoneIndex] = useState(null);
  const [timelineYearThreshold, setTimelineYearThreshold] = useState(0.796);
  const [timelineYearThreshold2, setTimelineYearThreshold2] = useState(0.796);
  const [milestoneThresholds, setMilestoneThresholds] = useState([]);
  const [clickToOpenTooltip, setClickToOpenTooltip] = useState(null);
  const [timelineTooltip, setTimelineTooltip] = useState(null);
  const timelineHeight = useRef(3000);
  const [showScrollToContinue, setShowScrollToContinue] = useState(false);
  const [paragraphTextVisible, setParagraphTextVisible] = useState(false);
  const [showReplayButton, setShowReplayButton] = useState(false);
  // const [showNav, setShowNav] = useState(false)
  const setTimelinePercentageOffset = (offset) => {
    minimapUpdater.call("update", null, offset);
    milestoneUpdater.call("update", null, offset);
  };
  useEffect(() => {
    milestoneUpdater.on(
      "update",
      throttle(
        (offset) => {
          let nextMilestone = null;
          milestoneThresholds.forEach((m) => {
            if (offset > m.value) {
              nextMilestone = m;
            }
          });
          if (nextMilestone) {
            if (nextMilestone.index !== prevMilestoneIndex) {
              const prevMilestone = milestoneThresholds[prevMilestoneIndex];
              if (prevMilestone) {
                prevMilestone.hoverOff();
              }
              nextMilestone.hover();
              prevMilestoneIndex = nextMilestone.index;
            }
          }
          setTimelineYear((timelineYear) => {
            const yearOverThreshold = offset > timelineYearThreshold;
            // || offset * height > height - height / timelineScreenHeight
            // ^ this isn't accurate enough
            let nextTimelineYear = yearOverThreshold ? 2021 : 2020;
            const yearOverThreshold2 = offset > timelineYearThreshold2;
            nextTimelineYear = yearOverThreshold2 ? 2022 : nextTimelineYear;
            if (nextTimelineYear && nextTimelineYear !== timelineYear) {
              return nextTimelineYear;
            }


            return timelineYear;
          });
        },
        100,
        {
          leading: true,
          trailing: true,
        }
      )
    );
  }, [milestoneThresholds, timelineYearThreshold, timelineYearThreshold2]);

  useEffect(() => {
    setTimelineColumnHeaders((columnHeaders) => {
      if (columnHeaders.length) {
        const nextColumnHeaders = [...columnHeaders];
        nextColumnHeaders[0] = generateTextureCanvas(timelineYear, 16 * 2, {
          font: "DamienDisplay",
          weight: "bold",
          alignText: "",
        });
        return nextColumnHeaders;
      }
      return columnHeaders;
    });
  }, [timelineYear]);

  /*
      due to the way items are instanced, we need a couple pieces of information to keep track of active itmes
      cards look like: (types anyone??)
      hovered cards:
        {
          instanceId: index of item within instance,
          mesh: instanceMesh threejs object that contains item
        }
    */
  const hoveredCards = useRef([]);
  const hoveredLink = useRef(false);
  const hoveredClose = useRef(false);
  const clickedMonth = useRef(false)
  /*
      clicked cards slightly different,
      depending on how it's constructed might not have all mesh?
      {
        instanceId: index of item within instance,
        mesh: instanceMesh threejs object that contains item
        instanceIndex: index of instanceMesh object
        textMesh: new mesh containing item text
      }
    */
  const [clickedCards, setClickedCards] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [containSize, setContainSize] = useState(
    Math.min(window.innerWidth, window.innerHeight)
  );
  const [coverSize, setCoverSize] = useState(
    Math.max(window.innerWidth, window.innerHeight)
  );

  const openCardItemScale = pMap(height, 700, 1000, 0.65, 0.35, true);
  const openTooltipScale = pMap(height, 700, 1000, 0.55, 0.375, true);

  useEffect(() => {
    function resize() {
      const outerWidth = container.current.clientWidth;
      const outerHeight = window.innerHeight;
      const padding = 0;
      const width = outerWidth - padding * 2;
      const height = outerHeight - padding * 2;
      const containSize = Math.min(width, height);
      const coverSize = Math.max(outerWidth, outerHeight);
      setWidth(width);
      setHeight(height);
      setContainSize(containSize);
      setCoverSize(coverSize);
    }

    resize();

    function resetScroll() {
      clearStepEnterTimeouts();
      clearOpenCardTimeouts();
      setShowScrollToContinue(false);
      setStepIndex(0);
      setPositionIndex(0);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
      const newStepWeights = stepWeights.current.weights.map((w, i) =>
        i === 0 ? 1 : 0
      );
      new TWEEN.Tween(stepWeights.current)
        .to({ weights: newStepWeights }, 2000)
        .easing(TWEEN.Easing.Linear.None)
        .start();
      window.document.body.classList.add("noScroll");
      window.document.body.classList.remove("scroll");

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "auto",
        });
        new TWEEN.Tween(stepWeights.current)
          .to({ weights: newStepWeights }, 2000)
          .easing(TWEEN.Easing.Linear.None)
          .start();
      }, 100);
    }
    resetScroll();

    window.addEventListener("resize", resize);
    window.addEventListener("beforeunload", resetScroll);
    window.addEventListener("unload", resetScroll);

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("beforeunload", resetScroll);
      window.removeEventListener("unload", resetScroll);
    };
  }, []);

  const unclickCards = useCallback(
    (inputCards = []) => {
      hoveredLink.current = false;
      hoveredClose.current = false;
      const all = [...inputCards, ...clickedCards];
      all.forEach((card) => {
        new TWEEN.Tween({ scale: 1 })
          .to({ scale: 0 }, 500)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate(({ scale }) => {
            if (card.textMesh.userData.children[0]) {
              card.textMesh.userData.children[0].material.opacity = scale;
              card.textMesh.scale.setScalar(scale * openCardItemScale);
            }
          })
          .onComplete(() => {
            // remove from stack
            setIsCardHovered(false)
            setClickedCards((cards) => {
              const i = cards.findIndex((d) => d === card);
              let newCards = cards;
              if (i !== -1) {
                newCards = [...cards];
                newCards.splice(i, 1);
                card.textMesh.userData.children.forEach((c) => {
                  c.material.map.dispose();
                  c.material.dispose();
                  c.texture.dispose();
                  c.geometry.dispose();
                  c.userData.onPointerOver = null;
                  c.userData.onPointerOut = null;
                  c.userData.onClick = null;
                  return c;
                });
              }
              return newCards;
            });
          })
          .start();
      });
    },
    [clickedCards, openCardItemScale]
  );

  const [isCardHovered, setIsCardHovered] = useState(false);
  const overCard = () => {
    setIsCardHovered(true);
  }

  const offCard = () => {
    setTimeout(() => {
      setIsCardHovered(false);

    }, 100)
  }

  const narrow = width <= breakpoint



  // each position has a position function that places the dot,
  // weights are assigned to each dot for each step
  // we tween the weight values to animate between the step positions
  // the intro is treated a little differenty...
  const positions = useMemo(() => {
    const getInsightSpherePosition = ({ label, color, filter }) => {
      return {
        label,
        useOffset: false,
        position: (point, pointIndex, object, time) => {
          if (!point.userData.dataRow.visibleInIntro) {
            object.position.set(
              point.userData.randomOffscreen.x,
              point.userData.randomOffscreen.y,
              point.userData.randomOffscreen.z
            )
            return
          }
          const radius = Math.abs(
            coverSize *
              0.6 *
              (Math.abs(Math.sin(pointIndex * 4 + time * 0.01)) + 0.2)
          );
          const xOffset = 0;
          point.userData.randomSpherical.r = radius;
          const phiOffset = time * 0.02;
          if (filter && !filter(point)) {
            object.position.set(
              point.userData.randomOffscreen.x,
              point.userData.randomOffscreen.y,
              point.userData.randomOffscreen.z
            );
          } else {
            object.position.set(
              xOffset +
                point.userData.randomSpherical.r *
                  Math.cos(point.userData.randomSpherical.phi + phiOffset) *
                  Math.sin(point.userData.randomSpherical.theta),
              point.userData.randomSpherical.r *
                Math.cos(point.userData.randomSpherical.theta),
              point.userData.randomSpherical.r *
                Math.sin(point.userData.randomSpherical.phi + phiOffset) *
                Math.sin(point.userData.randomSpherical.theta)
            );
          }
          object.scale.setScalar(insightsItemScale);
        },
        color,
      };
    };
    return [
      {
        label: "offscreen",
        useOffset: true,
        position: (point, pointIndex, object, time) => {
          // console.log(point)
          object.position.set(
            point.userData.randomOffscreen.x,
            point.userData.randomOffscreen.y,
            point.userData.randomOffscreen.z
          );
        },
      },
      {
        label: "inRandomTitle",
        useOffset: false,
        position: (point, pointIndex, object, time) => {
          if (!point.userData.dataRow.visibleInIntro) {
            object.position.set(
              point.userData.randomOffscreen.x,
              point.userData.randomOffscreen.y,
              point.userData.randomOffscreen.z
            )
            return
          }
          const x0 = Math.cos(time + point.userData.randomOffscreen.x) * 2;
          const y0 = Math.sin(time + point.userData.randomOffscreen.y) * 2;
          const z0 = Math.sin(time + point.userData.randomOffscreen.x) * 2;

          if (point.userData.transitionTitle) {
            point.userData.randomSpherical.r = containSize / 3;

            const offset =
              Math.min(
                Math.min(
                  point.userData.randomInText.y,
                  point.userData.randomInTextB.y
                ) / height,
                Math.min(
                  point.userData.randomInText.x,
                  point.userData.randomInTextB.x
                ) / width
              ) / -2;

            const frequency = (Math.max(0, time - 3.375) + offset) / 1.25;
            const amplitude = 2.25;
            const wave = Math.sin(frequency) * amplitude;

            const weight1 = Math.min(
              1,
              Math.max(0, (1 + wave) / (amplitude - 0.5))
            );
            const weight2 = Math.min(
              1,
              Math.max(0, (1 + wave * -1) / (amplitude - 0.5))
            );
            const weightS = TWEEN.Easing.Quadratic.InOut(
              1 - Math.min(1, Math.abs(wave / (amplitude - 1)))
            );

            const phiOffset = time * 0.5;

            const x1 = point.userData.randomInText.x * weight1;
            const x2 = point.userData.randomInTextB.x * weight2;
            const xS =
              point.userData.randomSpherical.r *
              Math.cos(point.userData.randomSpherical.phi + phiOffset) *
              Math.sin(point.userData.randomSpherical.theta);
            const xR = xS * weightS;

            const y1 = point.userData.randomInText.y * weight1;
            const y2 = point.userData.randomInTextB.y * weight2;
            const yY = height / 8;
            const yS =
              yY +
              point.userData.randomSpherical.r *
                Math.cos(point.userData.randomSpherical.theta);
            const yR = yS * weightS;

            const zS =
              point.userData.randomSpherical.r *
              Math.sin(point.userData.randomSpherical.phi + phiOffset) *
              Math.sin(point.userData.randomSpherical.theta);
            const zR = zS * weightS;

            object.position.set(x0 + x1 + x2 + xR, y0 + y1 + y2 + yR, z0 + zR);
          } else {
            object.position.set(
              x0 + point.userData.randomInText.x,
              y0 + point.userData.randomInText.y,
              z0
            );
          }
        },
      },
      getInsightSpherePosition({
        label: "covidSphere",
        color: (point, pointIndex, outputColor) => {
          let color = threeWhite;
          const { isCovidRelated } = point.userData.dataRow;
          if (isCovidRelated) {
            color = threeHardcodedColors["Covid Related"];
          }
          outputColor.copy(color);
        },
      }),
      getInsightSpherePosition({
        label: "mediaAndTwitter",
        color: (point, pointIndex, outputColor) => {
          const tweet = point.userData.dataRow.type === "tweet";
          let color = tweet
            ? threeHardcodedColors.tweet
            : threeHardcodedColors.media;
          outputColor.copy(color);
        },
      }),
      getInsightSpherePosition({
        label: "mediaOnly",
        filter: (point) => point.userData.dataRow.type === "media",
        color: (point, pointIndex, outputColor) => {
          const tweet = point.userData.dataRow.type === "tweet";
          let color = tweet
            ? threeHardcodedColors.tweet
            : threeHardcodedColors.media;
          outputColor.copy(color);
        },
      }),
      {
        label: "timelineDefault",
        useOffset: false,
        position: (point, pointIndex, object) => {
          const scaledX = xDayIndexScale(point.userData.dataRow.dayOfIndex);
          const xDirection = point.userData.dataRow.type === "tweet" ? 1 : -1;
          const columnOffset = width / 5;
          const x = scaledX * xDirection + (narrow ?  7.5 : 12) * xDirection - columnOffset;
          object.position.set(
            x,
            yTimeScale(point.userData.dataRow.date) + timelineYOffset,
            0
          );
          object.scale.setScalar(timelineItemScale);
        },
        color: (point, pointIndex, outputColor) => {
          const tweet = point.userData.dataRow.type === "tweet";
          let color = tweet
            ? threeHardcodedColors.tweet
            : threeHardcodedColors.media;
          outputColor.copy(color);
        },
      },
      ...timelineAttributes.map((timelineAttribute, timelineAttributeIndex) => {
        const { field } = timelineAttribute;
        return {
          useOffset: false,
          label: "timelineAttribute",
          position: (point, pointIndex, object) => {
            const scaledX = xDayIndexScale(
              point.userData.timelineAttributeData[timelineAttributeIndex]
                .positionIndex
            );
            const xDirection = point.userData.dataRow.type === "tweet" ? 1 : -1;
            const columnOffset = width / 5;
            const x = scaledX * xDirection + (narrow ?  7.5 : 12) * xDirection - columnOffset;
            object.position.set(
              x,
              yTimeScale(point.userData.dataRow.date) + timelineYOffset,
              0
            );
            object.scale.setScalar(timelineItemScale);
          },
          color: (point, pointIndex, outputColor) => {
            let color = threeWhite;
            const value = point.userData.dataRow[field];
            if (
              (field === "incident_type" ||
                field === "incident_covid_related") &&
              threeHardcodedColors[value]
            ) {
              color = threeHardcodedColors[value];
            } else {
              const attributeData =
                point.userData.timelineAttributeData[timelineAttributeIndex];
              const { valueColorIndex } = attributeData;
              if (valueColorIndex != null) {
                color = threeColors[valueColorIndex % threeColors.length];
              }
            }
            outputColor.copy(color);
          },
        };
      }),
    ];
  }, [width, height, containSize, coverSize, narrow]);

  const inTimeline = useCallback(() => {
    let tweenedTimelinePositionIndex = positions.findIndex(
      (d) => d.label === "timelineAttribute"
    );
    return (
      positionIndex ===
        positions.findIndex((d) => d.label === "timelineDefault") ||
      (positionIndex >= tweenedTimelinePositionIndex &&
        positionIndex <
          tweenedTimelinePositionIndex + timelineAttributes.length)
    );
  }, [positionIndex, positions]);

  const openingPrevOrNext = useRef(false)
  const prevCard = useCallback((row, obj) => () => {
    unclickCards()
    openingPrevOrNext.current = true
    const newCard = getSpecificCard(instancedMeshGroups.current,row.prevItem);
    setTimeout(() => {
      openCard(newCard, { showArrowButtons: true });
      openingPrevOrNext.current = false
    }, 500)
  }, [clickedCards, unclickCards])
  const nextCard = useCallback((row, obj) => () => {
    unclickCards()
    openingPrevOrNext.current = true
    const newCard = getSpecificCard(instancedMeshGroups.current,row.nextItem);
    setTimeout(() => {
      openingPrevOrNext.current = false
      openCard(newCard, { showArrowButtons: true });

    }, 500)

  }, [clickedCards, unclickCards])

  const openCard = useCallback(
    (card, additionalTextOptions) => {
      if (!card) {
        console.log("tried top open null card");
        return;
      }
      const obj =
        instancedMeshGroups.current[card.instanceIndex][card.instanceId];
      const row = obj.userData.dataRow;
      let textString = row.tweet_text
        ? row.tweet_text
        : row.incident_description;
      textString = textString
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/▓/g, "█");

      let instanceColor = new Color("black");
      const instancedMeshRef = instancedMeshRefs.current[card.instanceIndex];
      if (instancedMeshRef) {
        instancedMeshRef.getColorAt(card.instanceId, instanceColor);
      }

      const backgroundFill = "#ffffff";
      const textFill = "#000000";
      const stroke = `#${instanceColor.getHexString()}`;

      const textOptions = {
        isCard: true,
        split: true,
        font: "BeausiteClassicWeb",
        backgroundFill,
        textFill,
        stroke,
        showCloseButton: true,
        showArrowButtons: narrow && inTimeline(),
        ...additionalTextOptions,
      };
      textOptions.date = `${
        months[row.date.getMonth()]
      } ${row.date.getDate()}, ${row.date.getFullYear()}`;
      if (row.type === "tweet") {
        textOptions.drawTwitterHeader = true;
        if (row.source_unavailable === "True") {
          textOptions.showCensoredTextLink = true;
        }
      }
      if (row.type === "media") {
        textOptions.drawNewsHeader = true;
        textOptions.mediaSource = row.source_name;
        textOptions.mediaLocation =
          row.incident_location === ""
            ? row.incident_location_type
            : row.incident_location;
      }
      const text = generateTextureCanvas(textString, 24, textOptions);
      card.textMesh = new Object3D();
      card.textMesh.userData.children = [];
      card.textMesh.userData.children.push(text);
      card.timelineVector = new Vector3();

      // card.textMesh.onPointerOver = overCard
      // card.userData.onPointerOut = offCard
      const padding = 20;
      const headerHeight = 120;
      if (textOptions.showCloseButton) {
        const closeOptions = {
          isTarget: true,
          backgroundFill: "rgba(255, 0, 0, 0)",
          width: text.width - padding / 2,
          height: text.rectHeight,
        };

        const close = generateTextureCanvas("text close", 24, closeOptions);
        close.userData.x = padding / 2;
        close.userData.y = padding * 2.5;
        if (row.type === 'media') {
          closeOptions.height -= headerHeight
          close.userData.y -= headerHeight
        }
        close.userData.onPointerOver = () => {
          hoverOffCardMeshes();
          hoveredClose.current = true;
          toggleMousePointerHand(true);
        };
        close.userData.onPointerOut = () => {
          hoverOffCardMeshes();
          hoveredClose.current = false;
          toggleMousePointerHand(false);
        };
        close.userData.onClick = () => {
          hoveredClose.current = true
        }
        card.textMesh.userData.children.push(close);
      }
      if (row.type === "media") {

        const linkOptions = {
          isTarget: true,
          backgroundFill: "rgba(255, 0, 0, 0)",
          width: text.width - padding * 3.5 ,
          height: headerHeight + padding,
        };
        const link = generateTextureCanvas("text link", 24, linkOptions);
        link.userData.x = -padding * 1.0625 - closeImageSize;
        link.userData.y =
          text.rectHeight / 2 - (headerHeight / 2 - padding / 2);
        link.userData.onPointerOver = () => {
          hoverOffCardMeshes();
          hoveredLink.current = true;
          toggleMousePointerHand(true);
        };
        link.userData.onPointerOut = () => {
          hoverOffCardMeshes();
          hoveredLink.current = false;
          toggleMousePointerHand(false);
        };
        link.userData.onClick = () => {
          hoverOffCardMeshes();
          hoveredLink.current = false;
          window.open(row.source_url);
        };
        card.textMesh.userData.children.push(link);
      }

      if (textOptions.showArrowButtons) {
        const arrowLinkOptions = {
          isTarget: true,
          backgroundFill: 'rgba(255, 0, 0, 0)',
          width: 40 * 2,
          height: 40 * 2
        }
        const arrow = generateTextureCanvas("text arrow", 24, arrowLinkOptions);
        arrow.userData.x = text.width * 0.5 - arrowLinkOptions.width * 2.75 + padding
        arrow.userData.y = -text.rectHeight * 0.5 - arrowLinkOptions.height / 2 + padding * 0.5
        arrow.userData.onPointerOver = () => {
          hoveredLink.current = true
        }
        arrow.userData.onPointerOut = () => {
          hoveredLink.current = false
        }
        arrow.userData.onClick = prevCard(row, obj)
        card.textMesh.userData.children.push(arrow)
        const arrow2 = generateTextureCanvas("text arrow", 24, arrowLinkOptions);
        arrow2.userData.x = text.width * 0.5 - arrowLinkOptions.width + padding
        arrow2.userData.y = -text.rectHeight * 0.5 - arrowLinkOptions.height / 2 + padding * 0.5
        arrow2.userData.onClick = nextCard(row, obj)
        arrow2.userData.onPointerOver = arrow.userData.onPointerOver
        arrow2.userData.onPointerOut = arrow.userData.onPointerOut
        card.textMesh.userData.children.push(arrow2)

      }

      card.textMesh.position.copy(obj.userData.toPosition.position);
      card.textMesh.renderOrder = 999;

      new TWEEN.Tween({ t: 0 })
        .to({ t: 1 }, 700)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate(({ t }) => {
          card.textMesh.userData.children[0].material.opacity = t;
          card.textMesh.scale.setScalar(t * openCardItemScale);
        })
        .start();
      if (clickedCards.length) {
        unclickCards();
      }
      setClickedCards((cards) => [...cards, card]);
    },
    [clickedCards, unclickCards, openCardItemScale, inTimeline, narrow, prevCard, nextCard]
  );


  const countPositions = positions.length;

  const timelineDefaultPositionIndex = positions.findIndex(
    (d) => d.label === "timelineDefault"
  );
  const timelineAttributesPositionIndex = positions.findIndex(
    (d) => d.label === "timelineAttribute"
  );

  const stepEnterTimeouts = useRef([]);
  const clickOpenCardTimeouts = useRef([]);
  const clearStepEnterTimeouts = () => {
    stepEnterTimeouts.current.forEach((timeout) => clearTimeout(timeout));
    stepEnterTimeouts.current.length = 0;
  };
  const clearOpenCardTimeouts = () => {
    clickOpenCardTimeouts.current.forEach((timeout) => clearTimeout(timeout));
    clickOpenCardTimeouts.current.length = 0;
  };
  /* set timeout helper, helpful with storing timeout ids for clearing later
    some we want to clear when we leave the scrolly step
    others we want to clear when a successful click interaction occurs
    */
  const stepTimeout = (clearOnLeave, clearOnClick, f, time) => {
    const timeout = setTimeout(f, time);
    if (clearOnLeave) stepEnterTimeouts.current.push(timeout);
    if (clearOnClick) clickOpenCardTimeouts.current.push(timeout);
  };

  useLayoutEffect(() => {
    if (!window.document.body.classList.contains("scroll")) {
      window.document.body.classList.add("noScroll");
    }
  }, []);

  const offsetLeft = new Vector3(-width / 8, 0, 0);

  const toggleClickToOpenTooltip = useCallback(
    (open, callback = () => {}) => {
      const duration = 300;
      if (clickToOpenTooltip) {
        if (clickToOpenTooltip.ref) {
          if (clickToOpenTooltip.tween) {
            clickToOpenTooltip.tween.stop();
          }
          const t0 = open ? 0 : 1;
          const t1 = 1 - t0;
          const scalar = (t) => t * openTooltipScale;
          if (clickToOpenTooltip.ref.material.opacity !== t1) {
            clickToOpenTooltip.tween = new TWEEN.Tween({ t: t0 })
              .to({ t: t1 }, duration)
              .easing(TWEEN.Easing.Quadratic.InOut)
              .onUpdate(({ t }) => {
                clickToOpenTooltip.ref.material.opacity = t;
                clickToOpenTooltip.ref.scale.setScalar(scalar(1));
              })
              .onComplete(callback)
              .start();
          }
        }
      }
    },
    [clickToOpenTooltip, openTooltipScale]
  );

  const toggleTimelineTooltip = useCallback(
    (open, callback = () => {}) => {
      const duration = 300;
      if (timelineTooltip.ref) {
        if (timelineTooltip.tween) {
          timelineTooltip.tween.stop();
        }
        const t0 = open ? 0 : 1;
        const t1 = 1 - t0;
        const scalar = (t) => t * openTooltipScale;
        if (timelineTooltip.ref.material.opacity !== t1) {
          timelineTooltip.tween = new TWEEN.Tween({ t: t0 })
            .to({ t: t1 }, duration)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate(({ t }) => {
              timelineTooltip.ref.material.opacity = t;
              timelineTooltip.ref.scale.setScalar(scalar(1));
            })
            .onComplete(callback)
            .start();
        }
      }
    },
    [timelineTooltip, openTooltipScale]
  );

  const onLeaveTimeline = useCallback(() => {
    hoveredCards.current = [];
    unclickCards();
    clearStepEnterTimeouts();
    setScrolledToTimeline(false);
    timelineYOffset = 0;
    setTimelinePercentageOffset(0);
    textTextureMaterials.forEach((material) => {
      material.depthTest = false;
    });
  }, [textTextureMaterials, unclickCards]);

  const offsetTop = useMemo(() => new Vector3(0, -height / 6, 0, 0), [height]);

  const touchScreen = window.matchMedia("(hover: none)").matches;
  const toolTipVerb = touchScreen ? "Tap" : "Click";
  const objectToScroll = touchScreen ? container.current : window;
  const currentScrollProperty = touchScreen ? "scrollTop" : "scrollY";
  // each step has a newStepPosition that indexes into the above positions array
  // a step's position can be overriden (e.g. in setTimeouts) via positionOverrides
  const steps = useMemo(() => {
    const narrow = width <= breakpoint;
    const advancePageScroll = (time) => {
      stepTimeout(
        true,
        false,
        () => {
          if (window.document.body.classList.contains("noScroll")) {
            objectToScroll.scrollTo({
              left: 0,
              top: objectToScroll[currentScrollProperty] + window.innerHeight,
            });
          }
        },
        time
      );
    };
    return [
      {
        height: 1,
        inNav: true,
        navLabel: "Title",
        introOver: () => {
          advancePageScroll(0);
        },
        content: (
          <div
            className={classnames("disclaimer replayButton", {
              showReplayButton,
            })}
            onClick={replay}
          >
            <div className="continue">Replay</div>
          </div>
        ),
        onEnter: () => {
          stepTimeout(
            true,
            false,
            () => {
              if (introStepIndex === null) {
                setShowReplayButton(true);
              }
            },
            2000
          );
        },
        onLeave: () => {
          setShowReplayButton(false);
        },
      },
      {
        height: 1,
        newPositionIndex: positions.findIndex((d) => d.label === "offscreen"),
        content: (
          <div
            style={{ width: narrow ? "unset" : "22em" }}
            className={classnames("fullscreenCopy paragraph blockBackground", {
              visible: paragraphTextVisible,
            })}
          >
            Asian Americans and Pacific Islanders (AAPI) have faced a barrage of
            hate coinciding with the COVID-19 pandemic.
          </div>
        ),
        onEnter: () => {
          setParagraphTextVisible(true);
          if (window.document.body.classList.contains("noScroll")) {
            stepTimeout(true, true, () => setParagraphTextVisible(false), 5500);
          }
          advancePageScroll(6000);
        },
        onLeave: () => {
          clearStepEnterTimeouts();
        },
      },
      {
        height: 1,
        inNav: true,
        navLabel: "News Reports",
        newPositionIndex: positions.findIndex((d) => d.label === "mediaOnly"),
        content: (
          <div
            style={{ width: narrow ? "unset" : "24em" }}
            className={classnames("fullscreenCopy paragraph blockBackground", {
              visible: paragraphTextVisible,
            })}
          >
            Since January 2020, the{" "}
            <span style={{ color: hardcodedColors.media }}>news</span> has
            reported hundreds of anti-Asian hate incidents.
          </div>
        ),
        openCardOffset: offsetTop,
        onEnter: () => {
          clearStepEnterTimeouts();
          setParagraphTextVisible(true);
          if (window.document.body.classList.contains("noScroll")) {
            stepTimeout(
              true,
              true,
              () => setParagraphTextVisible(false),
              7000 - 500
            );
          } else if (window.document.body.classList.contains("scroll")) {
            stepTimeout(
              true,
              false,
              () => {
                setShowScrollToContinue(true);
              },
              1000
            );
          }
          advancePageScroll(7000);
        },
        onLeave: () => {
          clearStepEnterTimeouts();
          unclickCards();
          setShowScrollToContinue(false);
        },
      },
      {
        height: 1,
        newPositionIndex: positions.findIndex(
          (d) => d.label === "mediaAndTwitter"
        ),
        content: (
          <div
            style={{ width: narrow ? "unset" : "24em" }}
            className={classnames("fullscreenCopy paragraph blockBackground", {
              visible: paragraphTextVisible,
            })}
          >
            A review of{" "}
            <span style={{ color: hardcodedColors.tweet }}>Twitter</span>{" "}
            suggests the volume of hate targeting AAPI communities is far
            greater than what is reported in the news.
          </div>
        ),
        openCardOffset: offsetTop,
        onEnter: () => {
          clearStepEnterTimeouts();
          setParagraphTextVisible(true);

          if (window.document.body.classList.contains("noScroll")) {
            stepTimeout(
              true,
              true,
              () => setParagraphTextVisible(false),
              8000 - 500
            );
          }
          advancePageScroll(8000);
        },
        onLeave: () => {
          clearStepEnterTimeouts();
          unclickCards();
        },
      },
      {
        height: 1,
        newPositionIndex: positions.findIndex(
          (d) => d.label === "mediaAndTwitter"
        ),
        content: null,
        openCardOffset: offsetTop,
        onEnter: () => {
          clearStepEnterTimeouts();
          setParagraphTextVisible(true);

          stepTimeout(
            true,
            true,
            () => {
              toggleClickToOpenTooltip(true);
            },
            0
          );
          stepTimeout(
            true,
            true,
            () => {
              toggleClickToOpenTooltip(false);
            },
            7000
          );

          function autoplayCard(depth) {
            const duration = 12000;
            const delay = depth ? duration + 500 : 1500;
            stepTimeout(
              true,
              true,
              () => {
                const newCard = getRandomCard(
                  instancedMeshGroups.current,
                  (item) => {
                    return (
                      item.userData.dataRow.type === "tweet" &&
                      item.userData.dataRow.tweet_visualization_scene ===
                        "volume"
                    );
                  }
                );
                openCard(newCard);
                stepTimeout(
                  true,
                  true,
                  () => {
                    unclickCards([newCard]);
                  },
                  duration
                );
                autoplayCard(depth + 1);
              },
              delay
            );
          }

          unclickCards();
          autoplayCard(0);

          stepTimeout(
            true,
            false,
            () => {
              setShowScrollToContinue("background");
            },
            0
          );
          stepTimeout(
            false,
            false,
            () => {
              window.document.body.classList.remove("noScroll");
              window.document.body.classList.add("scroll");
              // setShowNav(true);
            },
            0
          );
        },
        onLeave: () => {
          clearStepEnterTimeouts();
          unclickCards();
          setShowScrollToContinue(false);
          toggleClickToOpenTooltip(false);
        },
      },
      {
        height: narrow ? 1.5 : 1,
        inNav: true,
        navLabel: "COVID-19",
        newPositionIndex: positions.findIndex((d) => d.label === "covidSphere"),
        content: (
          <div
            style={
              narrow
                ? { width: "unset", maxWidth: "unset", marginLeft: "unset" }
                : {
                    width: "19em",
                    maxWidth: "20em",
                    marginLeft: "55%",
                    transform: "translateY(-50%)",
                  }
            }
            className={classnames("blockBackground", {
              scrollCopy: !narrow,
              fullscreenCopy: narrow,
              paragraph: narrow,
              visible: paragraphTextVisible,
            })}
          >
            Nearly 1 in 4 incidents are directly related to the{" "}
            <span style={{ color: hardcodedColors["Covid Related"] }}>
              COVID-19
            </span>{" "}
            pandemic including targeted coughing, physical shunning, and
            accusations of causing the virus.
          </div>
        ),
        onEnter: () => {
          clearStepEnterTimeouts();
          stepTimeout(
            true,
            true,
            () => {
              toggleClickToOpenTooltip(true);
            },
            5000
          );
          stepTimeout(
            true,
            true,
            () => {
              toggleClickToOpenTooltip(false);
            },
            12000
          );

          function autoplayCard(depth) {
            const duration = 12000;
            const delay = depth ? duration + 500 : 5000 + 1000;
            stepTimeout(
              true,
              true,
              () => {
                const newCard = getRandomCard(
                  instancedMeshGroups.current,
                  (item) => {
                    return (
                      item.userData.dataRow.type === "tweet" &&
                      item.userData.dataRow.tweet_visualization_scene ===
                        "covid"
                    );
                  }
                );
                openCard(newCard);
                stepTimeout(
                  true,
                  true,
                  () => {
                    unclickCards([newCard]);
                  },
                  duration
                );
                autoplayCard(depth + 1);
              },
              delay
            );
          }

          unclickCards();
          autoplayCard(0);

          stepTimeout(
            true,
            false,
            () => {
              setShowScrollToContinue("background");
            },
            6000
          );
        },
        onLeave: () => {
          clearStepEnterTimeouts();
          unclickCards();
          toggleClickToOpenTooltip(false);
          setShowScrollToContinue(false);
        },
      },
      {
        height: 1,
        newPositionIndex: positions.findIndex((d) => d.label === "covidSphere"),
        content: (
          <div
            className="fullscreenCopy paragraph visible blockBackground"
            style={{ width: narrow ? "unset" : "29em" }}
          >
            Scroll down to explore an interactive timeline of hate incidents as
            reported in the news and on Twitter.
          </div>
        ),
        openCardOffset: offsetTop,
        onEnter: () => {
          clearStepEnterTimeouts();
          stepTimeout(
            true,
            false,
            () => {
              setShowScrollToContinue("background");
            },
            1000
          );
        },
        onLeave: () => {
          clearStepEnterTimeouts();
          unclickCards();
          setShowScrollToContinue(false);
        },
      },
      {
        timeline: true,
        inNav: true,
        navLabel: "Timeline",
        height: 1, // start with one screen height, set real height later once timeline in view
        newPositionIndex:
          positions.findIndex((d) => d.label === "timelineAttribute") +
          timelineAttributes.findIndex(
            (d) => d.field === "incident_covid_related"
          ),
        content: (() => {
          const selectAttribute = (event) => {
            const attributeIndex = +event.target.value;

            setSelectedTimelineAttributeIndex(attributeIndex);
            const timelineDivIndex = 7; // need this to refer to it's current position in this array
            let positionOverride =
              attributeIndex === -1
                ? timelineDefaultPositionIndex
                : timelineAttributesPositionIndex + attributeIndex;
            setPositionOverrides((o) => ({
              ...o,
              [timelineDivIndex]: positionOverride,
            }));
            setPositionIndex(positionOverride);
          };
          const positionFromMinimap = (percentage) => {
            const timelineDivIndex = 7; // need this to refer to it's current position in this array
            const timelineDivRef = timelineDivs.current[timelineDivIndex];
            if (!timelineDivRef) {
              console.log(timelineDivs.current);
              console.error("position from minimap with no active div.");
              return;
            }
            const dimensions = timelineDivRef.getBoundingClientRect();
            let newY =
              percentage * dimensions.height +
              (window.scrollY + dimensions.top);
            window.scrollTo(0, newY);
          };

          return (
            <div
              className={classnames("timelineUI", {
                narrow: width < breakpoint,
              })}
            >
              <div className="timelineSelect">
                Sort by
                <br />
                <select
                  value={selectedTimelineAttributeIndex}
                  onChange={selectAttribute}
                >
                  <option value={-1}>None</option>
                  {timelineAttributes.map((attr, i) => (
                    <option value={i} key={i}>
                      {attr.label || attr.field}
                    </option>
                  ))}
                </select>
              </div>
              <div className="timelineAttributes">
                {timelineAttributeData &&
                timelineAttributeData[selectedTimelineAttributeIndex]
                  ? timelineAttributeData[selectedTimelineAttributeIndex].map(
                      ([value], index) => {
                        const field =
                          timelineAttributes[selectedTimelineAttributeIndex]
                            .field;
                        if (
                          timelineAttributeValuesToHide[field] &&
                          timelineAttributeValuesToHide[field].includes(value)
                        ) {
                          return null;
                        }
                        const colorIndex =
                          timelineFieldValueColors[field][value];
                        let color = colors[colorIndex % colors.length];
                        if (
                          (field === "incident_type" ||
                            field === "incident_covid_related") &&
                          hardcodedColors[value]
                        ) {
                          color = hardcodedColors[value];
                        }
                        return (
                          <div className="attribute" key={index}>
                            <div style={{ backgroundColor: color }} />
                            {value}
                          </div>
                        );
                      }
                    )
                  : null}
              </div>
              <Minimap
                timelineHeight={timelineHeight.current}
                data={dataByDayAndType}
                xDayScale={xDayIndexScale}
                yTimeScale={yTimeScale}
                positionFromMinimap={positionFromMinimap}
              />
            </div>
          );
        })(),
        contentStyle: { height: "100%" },
        onEnter: () => {
          timelineYOffset = 0;
          textTextureMaterials.forEach((material) => {
            material.depthTest = true;
          });
          setTimelinePercentageOffset(0);
          if (!scrolledToTimeline) {
            stepEnterTimeouts.current.push(
              setTimeout(() => {
                setScrolledToTimeline(true);
              }, 2500)
            );
          }
          stepTimeout(
            true,
            true,
            () => {
              toggleTimelineTooltip(true);
            },
            2200
          );
          stepTimeout(
            true,
            true,
            () => {
              toggleTimelineTooltip(false);
            },
            9000
          );
        },
        onLeave: () => {
          onLeaveTimeline();
          toggleTimelineTooltip(false);
        },
      },
      {
        footer: true,
        height: 0.66,
        content: <Footer />,
        dontTriggerEnterLeave: true,
      },
    ];
    // }, [selectedTimelineAttributeIndex, timelineAttributeData, timelineFieldValueColors, dataByDayAndType, openCard, unclickCards, scrolledToTimeline, textTextureMaterials, timelineAttributesPositionIndex, timelineDefaultPositionIndex, /*visiblePointsByIncidentType,*/ timelineYearThreshold, positions, toggleClickToOpenTooltip, toggleTimelineTooltip, paragraphTextVisible, onLeaveTimeline, width, replay, showReplayButton, introStepIndex, offsetTop, objectToScroll, currentScrollProperty])
  }, [
    selectedTimelineAttributeIndex,
    timelineAttributeData,
    timelineFieldValueColors,
    dataByDayAndType,
    openCard,
    unclickCards,
    scrolledToTimeline,
    textTextureMaterials,
    timelineAttributesPositionIndex,
    timelineDefaultPositionIndex,
    positions,
    toggleClickToOpenTooltip,
    toggleTimelineTooltip,
    paragraphTextVisible,
    onLeaveTimeline,
    width,
    replay,
    showReplayButton,
    introStepIndex,
    offsetTop,
    objectToScroll,
    currentScrollProperty,
  ]);
  const timelineDivIndex = steps.findIndex((d) => d.timeline);

  // set the initial set of weights, to just focus on the first.. this might get overwritten later or quickly by weights from a different active step
  const stepWeights = useRef({
    weights: positions.reduce(
      (accumulator, next, index) => [...accumulator, index === 0 ? 1 : 0],
      []
    ),
  });

  const positionTimes = useRef(
    positions.reduce(
      (times, nextPosition) => ({ ...times, [nextPosition.label]: 0 }),
      {}
    )
  );
  useEffect(() => {
    positionTimes.current[positions[positionIndex].label] = 0;
    const newStepWeights = positions.reduce((accumulator, nextStep, index) => {
      // assign new weights based on which step we are on, activate the current step
      let weightValue = index === positionIndex ? 1 : 0;
      return [...accumulator, weightValue];
    }, []);
    // perhaps shouldn't tween if step weights are the same?
    new TWEEN.Tween(stepWeights.current)
      .to({ weights: newStepWeights }, 2000)
      .easing(TWEEN.Easing.Linear.None)
      .start();

    hoveredCards.current.length = 0;
  }, [positions, positionIndex]);


  const timelineButtonLabel = useMemo(() => {
    return generateTextureCanvas('Browse Tweets/News', 8 * 2 * 1.25, { underline : true } )
  }, [])
  useEffect(() => {
    if (data) {
      const dataByDay = Array.from(
        group(
          data,
          (d) =>
            `${d.date.getFullYear()}|${d.date.getMonth()}|${d.date.getDate()}`,
          (d) => d.type
        )
      ).map(([key, values]) => {
        const [year, month, day] = key.split("|");
        const date = new Date(year, month, day);
        const byType = Array.from(values).map(([type, values2]) => {
          return { type, values: values2 };
        });
        return { date, values: byType, valueMap: values };
      });
      const dateExtent = extent(dataByDay.map((d) => d.date));
      const sortedDayValues = dataByDay.map((d) =>
        max(d.values.map((d) => d.values.length))
      );
      sortedDayValues.sort((a, b) => b - a);
      // don't use the absolute max, some days have way too much to show, so let's just use a max that is close to the actual maximum
      const maxCount = sortedDayValues[2];

      dataByDay.forEach(({ day, values }) => {
        values.forEach(({ type, values }) => {
          values.forEach((row, rowIndex) => {
            row.dayOfIndex = rowIndex;
          });
        });
      });
      yTimeScale.domain(dateExtent);
      delayScale.domain(dateExtent).range([0, 1]);
      xDayIndexScale.domain([0, maxCount]);
      if (!monthLabelData.length) {
        const monthsInData = [
          ...new Set(
            dataByDay
              .map((d) => `${d.date.getMonth()}|${d.date.getFullYear()}`)
              .filter((d) => !d.includes("NaN"))
          ),
        ].map((d) => {
          const [month, year] = d.split("|");
          const date = new Date(year, month, 1);
          const label = date.toLocaleDateString("en-US", { month: "long" });
          const text = generateTextureCanvas(label, 9 * 2 * 1.5); // , 2048, 256);
          return {
            date,
            label,
            material: text.material,
            geometry: text.geometry,
          };
        });
        setMonthLabelData(monthsInData);
      }

      const titleTextGeometryWidth = 960;
      const padding = width < breakpoint ? 36 : Math.min(120, width / 10);
      const titleScaleRatio = Math.min(
        1,
        (width - padding) / titleTextGeometryWidth
      );
      const yOffset = 161; // calculated once at full size - see commented yValueExtent calc below
      const plusYOffset = new Vector3(0, yOffset, 0);
      const minusYOffset = new Vector3(0, -yOffset, 0);
      const plusYOffsetScaled = new Vector3(0, yOffset * titleScaleRatio, 0);
      const minusYOffsetScaled = new Vector3(0, -yOffset * titleScaleRatio, 0);

      if (!points.current.length) {
        let dataToUse = data;
        window.dataToUse = dataToUse;
        let covidIndex = 0;
        let nonCovidIndex = 0;

        points.current = dataToUse.map((row, rowIndex) => {
          let instancedMeshGroupIndex = -1;
          instancedMeshGroupIndex = Math.floor(
            Math.random() * uniqueTextureCount
          );
          const text = new Object3D();
          instancedMeshGroups.current[instancedMeshGroupIndex].push(text);

          text.userData.dataRow = row;
          const randomX = (Math.random() - 0.5) * width;
          const randomY = (Math.random() - 0.5) * height;
          text.position.x = randomX;
          text.position.y = randomY;

          text.userData.totalCount = dataToUse.length;
          if (text.userData.dataRow.isCovidRelated) {
            text.userData.covidIndex = covidIndex;
            covidIndex++;
          } else {
            text.userData.covidIndex = nonCovidIndex;
            nonCovidIndex++;
          }

          const randomSpherical = {
            r: containSize * 0.2,
            theta: Math.random() * Math.PI,
            phi: Math.random() * 2 * Math.PI,
          };
          text.userData.randomSpherical = randomSpherical;
          text.userData.randomInText = new Vector3();
          text.userData.randomInTextB = new Vector3();
          text.userData.toPosition = new Object3D();
          text.userData.morphObjects = Array.from({
            length: countPositions,
          }).map(() => new Object3D());
          text.userData.morphColors = Array.from({
            length: countPositions,
          }).map(() => new Color());
          text.userData.toColor = new Color();
          text.userData.dataIndex = rowIndex;
          const offscreenDirection = Math.floor(Math.random() * 4);
          let x = 0;
          let y = 0;
          const outerEdgeMargin = 200;
          if (offscreenDirection === 0) {
            x = width / 2 + outerEdgeMargin * Math.random();
            y = (Math.random() - 0.5) * height;
          } else if (offscreenDirection === 1) {
            x = (Math.random() - 0.5) * width;
            y = height / 2 + outerEdgeMargin * Math.random();
          } else if (offscreenDirection === 2) {
            x = -width / 2 - outerEdgeMargin * Math.random();
            y = (Math.random() - 0.5) * height;
          } else if (offscreenDirection === 3) {
            x = (Math.random() - 0.5) * width;
            y = -height / 2 - outerEdgeMargin * Math.random();
          }

          text.userData.randomOffscreen = new Vector3(x * 2, y * 2, 0);
          text.userData.timelineAttributeData = [];

          if (rowIndex % 3 === 0) {
            text.userData.transitionTitle = false;
            hSampler.sample(text.userData.randomInText);
            text.userData.randomInText.add(plusYOffset);
            text.userData.randomInText.multiplyScalar(titleScaleRatio);
            text.userData.randomInText.add(minusYOffsetScaled);
            text.userData.randomInTextB = text.userData.randomInText;
          } else {
            text.userData.transitionTitle = true;
            aSampler.sample(text.userData.randomInText);
            dSampler.sample(text.userData.randomInTextB);
            text.userData.randomInText.add(minusYOffset);
            text.userData.randomInTextB.add(minusYOffset);
            text.userData.randomInText.multiplyScalar(titleScaleRatio);
            text.userData.randomInTextB.multiplyScalar(titleScaleRatio);
            text.userData.randomInText.add(plusYOffsetScaled);
            text.userData.randomInTextB.add(plusYOffsetScaled);
          }
          return text;
        });

        const byIncidentType = Array.from(
          group(points.current, (d) => d.userData.dataRow.incident_type)
        );
        byIncidentType.forEach(
          ([incidentType, incidentTypeValues], incidentTypeIndex) => {
            incidentTypeValues.forEach((text, typeIndex) => {
              text.userData.incidentTypeIndex = incidentTypeIndex;
              text.userData.indexInIncidentType = typeIndex;
              text.userData.incidentTypeCount = incidentTypeValues.length;
            });
          }
        );

        // assign categories based on attribute values, used in timeline
        const timelineAttributeValues = [];
        const fieldValueColors = {};
        // for all the timeline values
        timelineAttributes.forEach((attribute) => {
          const { field } = attribute;
          fieldValueColors[field] = {};
          const fieldValuesToHide = timelineAttributeValuesToHide[field] || [];
          // first group by timeline attribute only, sort those so we know which is biggest
          const byField = Array.from(
            group(points.current, (d) => d.userData.dataRow[field])
          ).sort((a, b) =>
            field === "incident_covid_related"
              ? a[1].length - b[1].length
              : b[1].length - a[1].length
          );

          let valueColorIndex = 0;
          byField.forEach((value) => {
            if (
              timelineAttributeValuesToHide[field] &&
              timelineAttributeValuesToHide[field].includes(value[0])
            ) {
              return;
            }
            fieldValueColors[field][value[0]] = valueColorIndex;
            valueColorIndex++;
          });
          // group by date, type ( media vs tweet ) & attribute value
          const dataByDayTypeAttribute = Array.from(
            group(
              points.current,
              (d) =>
                `${d.userData.dataRow.date.getFullYear()}|${d.userData.dataRow.date.getMonth()}|${d.userData.dataRow.date.getDate()}`,
              (d) => d.userData.dataRow.type,
              (d) => d.userData.dataRow[field]
            )
          );

          // for each day of data
          dataByDayTypeAttribute.forEach(([day, dayValues]) => {
            // for both media & tweets
            Array.from(dayValues).forEach(([type, typeValues]) => {
              // go through the fields in order that we sorted them earlier
              let index = 0;
              byField.forEach(([value, values], fieldIndex) => {
                // get the values for this attribute
                const fieldValues = typeValues.get(value);
                if (fieldValues) {
                  // set positionIndex of index in day, index describing field value

                  fieldValues.forEach((obj3d) => {
                    let positionIndex = index;
                    if (fieldValuesToHide.includes(value)) {
                      positionIndex = 1000;
                    } else {
                      index++;
                    }
                    const valueColorIndex = fieldValueColors[field][value];
                    obj3d.userData.timelineAttributeData.push({
                      index: fieldIndex,
                      positionIndex,
                      valueColorIndex,
                    });
                  });
                }
              });
            });
          });
          timelineAttributeValues.push(byField);
        });
        let milestoneTextures = [];
        if (width > breakpoint) {
          milestoneTextures = milestoneData.map((milestone, milestoneIndex) => {
            const textString = `${
              months[milestone.Date.getMonth()]
            } ${milestone.Date.getDate()} - ${milestone.Text}`;
            const milestoneWidth = 360;
            const milestoneOptions = {
              alignText: "end",
              textFill: "#e0b72d",
              backgroundFill: "rgba(0, 0, 0, 0.85)",
              split: true,
              isMilestone: true,
              width: milestoneWidth,
            };
            const text = generateTextureCanvas(
              textString,
              9 * 2,
              milestoneOptions
            );
            text.material.opacity = milestoneIndex === 0 ? 1 : 0;
            return text;
          });
        }
        const clickToOpenTooltip = (() => {
          const backgroundFill = "#ffffff";
          const textFill = "#000000";
          const stroke = "#ffffff";

          const textOptions = {
            isCard: true,
            split: true,
            font: "BeausiteClassicWeb",
            backgroundFill,
            textFill,
            stroke,
            isTooltip: true,
          };
          const textString = `${toolTipVerb} any fragment to see a story.`;
          const text = generateTextureCanvas(textString, 24, textOptions);
          return text;
        })();

        setClickToOpenTooltip(clickToOpenTooltip);

        const timelineTooltip = (() => {
          const backgroundFill = "#ffffff";
          const textFill = "#000000";
          const stroke = "#ffffff";

          const textOptions = {
            isCard: true,
            split: true,
            delimiter: "|",
            font: "BeausiteClassicWeb",
            backgroundFill,
            textFill,
            stroke,
            isTooltip: true,
            width: 350,
          };


          let textString = `${toolTipVerb} any fragment to see a story.|Use the menu to sort by key features.`;
          if (narrow) {
            textString = 'Tap any month to see stories from that time.|Use the menu to sort by key features.'
          }
          const text = generateTextureCanvas(textString, 24, textOptions);
          return text;
        })();

        setTimelineTooltip(timelineTooltip);

        setMilestoneTextures(milestoneTextures);

        setTimelineColumnHeaders(
          ["2020", "News", "Twitter"].map((text) =>
            generateTextureCanvas(text, 16 * 2, {
              font: "DamienDisplay",
              weight: "bold",
              alignText: text === "News" ? "end" : "",
            })
          )
        );
        setTimelineAttributeData(timelineAttributeValues);
        setTimelineFieldValueColors(fieldValueColors);
        setDataByDayAndType(dataByDay);
      } else {
        // resize points here
        points.current = points.current.map((point) => {
          if (point.userData.transitionTitle) {
            aSampler.sample(point.userData.randomInText);
            dSampler.sample(point.userData.randomInTextB);
            point.userData.randomInText.add(minusYOffset);
            point.userData.randomInTextB.add(minusYOffset);
            point.userData.randomInText.multiplyScalar(titleScaleRatio);
            point.userData.randomInTextB.multiplyScalar(titleScaleRatio);
            point.userData.randomInText.add(plusYOffsetScaled);
            point.userData.randomInTextB.add(plusYOffsetScaled);
          } else {
            hSampler.sample(point.userData.randomInText);
            point.userData.randomInText.add(plusYOffset);
            point.userData.randomInText.multiplyScalar(titleScaleRatio);
            point.userData.randomInText.add(minusYOffsetScaled);
          }
          return point;
        });
      }
    }
  }, [
    data,
    countPositions,
    width,
    height,
    containSize,
    monthLabelData.length,
    toolTipVerb,
    narrow
  ]);

  useEffect(() => {
    const inT = inTimeline();
    document.documentElement.classList.toggle("snap", !inT);
    // window.document.body.classList.toggle('snap', !inT)
    const to = inT ? 1 : 0;
    if (timelineLabelTween) {
      timelineLabelTween.stop();
    }
    timelineLabelTween = new TWEEN.Tween(timelineLabelProgress)
      .to({ t: to }, 2000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  }, [inTimeline]);

  /*
      running hooks in a loop.. I guess that's frowned upon ... ???
      Number of paragraphs is fixed, we do this to put useInView interaction observer
      on each paragraph to determine where it is on screen.
      Then loop through the paragraphs on screen and set graph position based on paragarph..
      In theory you'd just create a separate paragraph component for this, but then they fight
      over determining which is active, rather than just picking the final one
    */
  /* eslint-disable react-hooks/rules-of-hooks,react-hooks/exhaustive-deps */
  let stepsInView = [];
  const timelineDivs = useRef([]);
  let content = (
    <div className="timelineScroll">
      {steps.map((step, index) => {
        // we want things to trigger when a div takes up 50% of the vertical screen

        let heightMultiplier = step.height;
        const threshold = 1 / (1 + heightMultiplier);
        const initialInView = index === 0;
        const [inViewRef, inView] = useInView({ threshold, initialInView });
        if (inView && !step.footer) {
          stepsInView.push(index);
        }
        const calculatedHeight = isNaN(height)
          ? heightMultiplier * window.innerHeight
          : heightMultiplier * height;
        const style = { height: calculatedHeight };
        let paddingTop = Math.min(calculatedHeight / 2, window.innerHeight / 2);
        let activeStep = index === stepIndex;
        if (step.timeline) {
          activeStep = index <= stepIndex && scrolledToTimeline;
          if (scrolledToTimeline) {
            style.height = timelineHeight.current;
          }
        }
        if (step.footer) {
          style.scrollSnapAlign = "unset";
          if (scrolledToTimeline) {
            activeStep = true;
          }
        }

        return (
          <div
            ref={(node) => (timelineDivs.current[index] = node)}
            className={classnames("pageScroll", { active: activeStep })}
            style={style}
            key={index}
          >
            <div ref={inViewRef} style={{ paddingTop }}>
              <div style={step.contentStyle}>{step.content}</div>
            </div>
          </div>
        );
      })}
    </div>
  );

  useEffect(() => {
    // always pick last spot
    if (stepsInView.length) {
      const newStepIndex = stepsInView[stepsInView.length - 1];
      if (newStepIndex !== stepIndex) {
        if (
          steps[stepIndex] &&
          steps[stepIndex].onLeave &&
          !steps[newStepIndex].dontTriggerEnterLeave
        ) {
          steps[stepIndex].onLeave();
        }
        if (
          steps[stepIndex] &&
          steps[stepIndex].footer &&
          steps[newStepIndex] &&
          !steps[newStepIndex].timeline
        ) {
          // avoid skipping timeline reset when scrolling back from footer to other step
          onLeaveTimeline();
        }
        setStepIndex(newStepIndex);
      }
      let { newPositionIndex } = steps[newStepIndex];
      if (newStepIndex in positionOverrides) {
        newPositionIndex = positionOverrides[newStepIndex];
      }
      if (newPositionIndex != null && positionIndex !== newPositionIndex) {
        setPositionIndex(newPositionIndex);
      }
    }
  });

  function replay() {
    window.document.body.classList.add("noScroll");
    window.document.body.classList.remove("scroll");
    steps[0].introOver();
  }

  // somewhat special case to kick off some more introduction copy after official Intro & Disclaimer are over
  useEffect(() => {
    if (introStepIndex === null) {
      steps[0].introOver();
    }
  }, [introStepIndex]);

  useEffect(() => {
    const step = steps[stepIndex];

    if (step && step.onEnter) {
      step.onEnter();
    }
  }, [stepIndex]);
  /* eslint-enable react-hooks/rules-of-hooks,react-hooks/exhaustive-deps */

  let percent = 0;

  const Updater = () => {
    useFrame((state) => {
      TWEEN.update();
      const time = state.clock.getElapsedTime();
      if (positionTimes.current[positions[positionIndex].label] === 0) {
        positionTimes.current[positions[positionIndex].label] = time;
      }
      const timelineDivRef = timelineDivs.current[timelineDivIndex];
      if (timelineDivRef) {
        const dimensions = timelineDivRef.getBoundingClientRect();
        percent = Math.max(0, Math.min(1, -dimensions.top / dimensions.height));
        let newTimelinePercentageOffset = 0;
        if (scrolledToTimeline) {
          newTimelinePercentageOffset = percent;
          const newYOffset = percent * timelineHeight.current;
          if (newYOffset !== timelineYOffset) {
            timelineYOffset = newYOffset;
            hoveredCards.current.length = 0;
          }
        } else {
          timelineYOffset = 0;
        }
        setTimelinePercentageOffset(newTimelinePercentageOffset);
      }
      if (timelineLabelRefs.current.length) {
        let timelineBlockHeight = 0
        timelineLabelRefs.current.forEach((timelineLabel, i) => {
          const fromY = -height * (1 - timelineLabelProgress.t);
          const yScalePos = yTimeScale(timelineLabel.userData.date)
          const toY = yScalePos * timelineLabelProgress.t;
          const y = fromY + toY + timelineYOffset;
          timelineLabel.position.setY(y);

          if (i < timelineLabelRefs.current.length - 1) {
            const nextY = yTimeScale(timelineLabelRefs.current[i + 1].userData.date)
            timelineBlockHeight = Math.abs(nextY - yScalePos)
          }
          if (timelineLabel.children && timelineLabel.children[2]) {
            timelineLabel.children[2].position.setY(-timelineBlockHeight / 2);
            timelineLabel.children[2].scale.setY(timelineBlockHeight)
          }
          // console.log(timelineLabel)
        });
      }
      if (milestoneRefs.current.length) {
        milestoneRefs.current.forEach((milestoneRef, milestoneIndex) => {
          const fromY = -height * (1 - timelineLabelProgress.t);
          const toY =
            yTimeScale(milestoneData[milestoneIndex].Date) *
            timelineLabelProgress.t;
          const y = fromY + toY + timelineYOffset;
          milestoneRef.position.setY(y);
        });
      }

      if (timelineColumnHeaderGroupRef.current) {
        const fromY = height * (1 - timelineLabelProgress.t);
        const toY =
          yTimeScale(yTimeScale.domain()[0]) * timelineLabelProgress.t;
        timelineColumnHeaderGroupRef.current.position.set(0, fromY + toY, 2);
      }

      if (instancedMeshRefs.current.length) {
        const nonNullRefs = instancedMeshRefs.current.filter((d) => !!d);
        instancedMeshGroups.current.forEach((groupItems, groupIndex) => {
          if (groupItems.length === 0) {
            return;
          }
          const instancedMeshRef = nonNullRefs[groupIndex];
          if (!instancedMeshRef) {
            console.log(
              "instanced mesh ref missing, hopefully due to dev hot reloading?"
            );
            return;
          }
          groupItems.forEach((point, index) => {
            point.userData.toPosition.position.setScalar(0);
            point.userData.toPosition.scale.setScalar(0);
            point.userData.toColor.setScalar(0);

            stepWeights.current.weights.forEach((weight, positionIndex) => {
              if (weight === 0) {
                return;
              }

              const matchingStepObject = positions[positionIndex];
              matchingStepObject.position(
                point,
                index,
                point.userData.morphObjects[positionIndex],
                time
              );

              let offset = 0;
              if (matchingStepObject.useOffset) {
                const baseOffset =
                  Math.min(
                    Math.min(
                      point.userData.morphObjects[positionIndex].position.y,
                      point.userData.toPosition.position.y
                    ) / height,
                    Math.min(
                      point.userData.morphObjects[positionIndex].position.x,
                      point.userData.toPosition.position.x
                    ) / width
                  ) / -2;
                const offsetScale = TWEEN.Easing.Quadratic.InOut(
                  1 - Math.abs(0.5 - weight) * 2
                );
                const scaledOffset = baseOffset * offsetScale;
                offset = scaledOffset;
              }

              const w = Math.min(1, Math.max(0, weight + offset));
              const xWeight = TWEEN.Easing.Quadratic.InOut(w);
              const yWeight = TWEEN.Easing.Cubic.InOut(w);

              point.userData.toPosition.position.set(
                point.userData.toPosition.position.x +
                  point.userData.morphObjects[positionIndex].position.x *
                    xWeight,
                point.userData.toPosition.position.y +
                  point.userData.morphObjects[positionIndex].position.y *
                    yWeight,
                point.userData.toPosition.position.z +
                  point.userData.morphObjects[positionIndex].position.z *
                    xWeight
              );
              point.userData.toPosition.scale.set(
                point.userData.toPosition.scale.x +
                  point.userData.morphObjects[positionIndex].scale.x * xWeight,
                point.userData.toPosition.scale.y +
                  point.userData.morphObjects[positionIndex].scale.y * yWeight,
                point.userData.toPosition.scale.z +
                  point.userData.morphObjects[positionIndex].scale.z * xWeight
              );
              const totalRotation = 0.35;
              point.userData.toPosition.rotation.set(
                ((time * groupIndex * totalRotation) / uniqueTextureCount +
                  index) %
                  twoPi,
                ((time * groupIndex * totalRotation) /
                  uniqueTextureCount /
                  0.35 +
                  index / groupItems.length) %
                  twoPi,
                ((time * groupIndex * totalRotation) / uniqueTextureCount +
                  index / groupItems.length) %
                  twoPi
              );

              if (matchingStepObject.color) {
                matchingStepObject.color(
                  point,
                  index,
                  point.userData.morphColors[positionIndex],
                  time
                );
                point.userData.toColor.setRGB(
                  point.userData.toColor.r +
                    point.userData.morphColors[positionIndex].r * weight,
                  point.userData.toColor.g +
                    point.userData.morphColors[positionIndex].g * weight,
                  point.userData.toColor.b +
                    point.userData.morphColors[positionIndex].b * weight
                );
              } else {
                point.userData.toColor.setRGB(
                  point.userData.toColor.r + threeWhite.r * weight,
                  point.userData.toColor.g + threeWhite.g * weight,
                  point.userData.toColor.b + threeWhite.b * weight
                );
              }
            });
            if (point.userData.deltaPosition && !point.userData.hovered) {
              point.userData.toPosition.position.add(point.userData.deltaPosition)
              point.userData.toPosition.rotation.x += point.userData.deltaRotation.x
              point.userData.toPosition.rotation.y += point.userData.deltaRotation.y
              point.userData.toPosition.rotation.z += point.userData.deltaRotation.z
            }
            if (hoveredCards.current.length) {
              let lastCard =
                hoveredCards.current[hoveredCards.current.length - 1];
              let matches =
                lastCard.instanceId === index &&
                lastCard.mesh === instancedMeshRef;
              if (matches) {
                if (!lastCard.rotation) {
                  lastCard.position =
                    point.userData.toPosition.position.clone();
                  lastCard.rotation =
                    point.userData.toPosition.rotation.clone();
                  lastCard.instanceIndex = groupIndex
                  point.userData.deltaPosition = new Vector3()
                  point.userData.deltaRotation = new Euler()
                }
                point.userData.deltaPosition.subVectors(lastCard.position, point.userData.toPosition.position)
                point.userData.deltaRotation.set(
                  lastCard.rotation.x - point.userData.toPosition.rotation.x,
                  lastCard.rotation.y - point.userData.toPosition.rotation.y,
                  lastCard.rotation.z - point.userData.toPosition.rotation.z
                )
                point.userData.hovered = true
                point.userData.toPosition.scale.multiplyScalar(2);
                point.userData.toPosition.rotation.copy(lastCard.rotation);
                point.userData.toPosition.position.copy(lastCard.position);
              }
            }
            clickedCards.reduce((accumulator, nextCard) => {
              if (accumulator) {
                return accumulator;
              }
              if (
                nextCard.instanceIndex === groupIndex &&
                nextCard.instanceId === index
              ) {
                const meshScale = nextCard.textMesh.scale.x / openCardItemScale;
                point.userData.toPosition.rotation.set(
                  point.userData.toPosition.rotation.x * (1 - meshScale),
                  point.userData.toPosition.rotation.y * (1 - meshScale),
                  point.userData.toPosition.rotation.z * (1 - meshScale)
                );
                return true;
              }
              return accumulator;
            }, false);
            if (inTimeline()) {
              point.userData.toPosition.rotation.set(
                point.userData.toPosition.rotation.x *
                  (1 - timelineLabelProgress.t),
                point.userData.toPosition.rotation.y *
                  (1 - timelineLabelProgress.t),
                point.userData.toPosition.rotation.z *
                  (1 - timelineLabelProgress.t)
              );
            }
            point.userData.toPosition.updateMatrix();
            instancedMeshRef.setMatrixAt(
              index,
              point.userData.toPosition.matrix
            );
            instancedMeshRef.setColorAt(index, point.userData.toColor);
          });
          instancedMeshRef.instanceMatrix.needsUpdate = true;
          // potentially only instanceColor flag when actually needed
          instancedMeshRef.instanceColor.needsUpdate = true;
        });
      }

      clickedCards.forEach((card) => {
        const srcObject =
          instancedMeshGroups.current[card.instanceIndex][card.instanceId]
            .userData.toPosition;
        if (inTimeline()) {
          if (width > breakpoint) {
            card.timelineVector.copy(srcObject.position).multiplyScalar(0.7);
          } else {
            card.timelineVector.setY(-60)
          }
          card.textMesh.position.lerpVectors(
            srcObject.position,
            card.timelineVector,
            card.textMesh.scale.x / openCardItemScale
          );
          card.textMesh.position.z = 3;
        } else {
          const stepDefault = steps[stepIndex].openCardOffset;
          const initialDefault = width > breakpoint ? offsetLeft : offsetTop;
          const defaultOffset = stepDefault ? stepDefault : initialDefault;
          const cardPosition = card.offset ? card.offset : defaultOffset;
          // right now the scale tweens from 0 to `openCardItemScale`, so we can interpolate the position of this from it's begining spot to just some other spot we want it to appear
          card.textMesh.position.lerpVectors(
            srcObject.position,
            cardPosition,
            card.textMesh.scale.x / openCardItemScale
          );
          card.textMesh.position.z = 2;
        }
      });
    });
    return null;
  };
  const instancedMeshRefs = useRef([]);

  const { request, exit, isFullscreen } = useFullscreen();

  const clickFullScreenButton = () => {
    if (isFullscreen) {
      exit();
    } else {
      request();
    }
  };

  /* eslint-disable react-hooks/rules-of-hooks */
  const PositionTextFunction = useCallback((props) => {
    // hmm this runs more often then it should... it might be ok though?
    const {
      width,
      height,
      data,
      inTimeline,
      isFullscreen,
      selectedTimelineAttributeIndex,
    } = props;
    const three = useThree();
    const { camera } = three;
    useEffect(() => {
      const top = 0;
      let topTimelinePadding = 0;

      /// https://stackoverflow.com/questions/13055214/mouse-canvas-x-y-to-three-js-world-x-y-z/13091694#13091694
      // numerators are margins
      const narrow = width <= breakpoint;
      const xMargins = narrow ? 16 : 220;
      const x = (xMargins / width) * 2 - 1;
      let topMargin = 100;
      if (narrow) {
        topMargin = 160;
        if (
          selectedTimelineAttributeIndex != null && timelineAttributes[selectedTimelineAttributeIndex]
        ) {
          const field = timelineAttributes[selectedTimelineAttributeIndex].field
          if (field === "incident_location_type") {
            topMargin = 220;
          } else if (field === 'incident_type') {
            topMargin = 180
          }

        } else {
          topMargin = 140;
        }
      }

      const y = -(topMargin / height) * 2 + 1;

      var pos1 = new Vector3(-x, y, 0.5);
      var pos2 = new Vector3();
      pos1.unproject(camera);

      pos1.sub(camera.position).normalize();
      var distance = -camera.position.z / pos1.z;
      pos2.copy(camera.position).add(pos1.multiplyScalar(distance));

      topTimelinePadding = pos2.y;

      const lineWidth = pos2.x * 2;

      setLineWidth(lineWidth);
      const dateExtent = yTimeScale.domain();
      const numDays = (dateExtent[1] - dateExtent[0]) / (1000 * 60 * 60 * 24);
      const dayHeight = narrow ? 6.66 : 11;
      const totalHeight = dayHeight * numDays;

      timelineHeight.current = totalHeight;
      yTimeScale.range([
        top + topTimelinePadding,
        top - totalHeight + topTimelinePadding,
      ]);
      // const paddingAdjust = top
      const thresholdDate = new Date(2021, 0, 1); // new Date(2020, 9, 15);
      const thresholdDate2 = new Date(2022, 0, 1); // new Date(2020, 9, 15);

      const newThreshold =
        (yTimeScale(thresholdDate) - topTimelinePadding) /
        (yTimeScale.range()[1] - topTimelinePadding);
      setTimelineYearThreshold(newThreshold);
      const newThreshold2 =
      (yTimeScale(thresholdDate2) - topTimelinePadding) /
      (yTimeScale.range()[1] - topTimelinePadding);
      setTimelineYearThreshold2(newThreshold2);
      const milestoneOffset = height / 4 / yTimeScale.range()[1];
      const milestoneThresholds = milestoneData.map((m, i) => {
        const y = yTimeScale(m.Date) / yTimeScale.range()[1];
        return {
          index: i,
          value: y + milestoneOffset * y,
          hover: hoverMilestone(i),
          hoverOff: hoverOffMilestone(i),
        };
      });
      setMilestoneThresholds(milestoneThresholds);

      const maxDayIndex = xDayIndexScale.domain()[1];
      const maxW = maxDayIndex * dayHeight * (timelineItemScale / 1.66666);
      xDayIndexScale.range([0, maxW]);
    }, [
      width,
      height,
      camera,
      data,
      inTimeline,
      isFullscreen,
      selectedTimelineAttributeIndex,
    ]);

    return null;
  }, []);
  /* eslint-enable react-hooks/rules-of-hooks */

  const hoverMesh = (event) => {
    // if (hoveredClose.current || hoveredLink.current) {
    //   return
    // }
    if (inTimeline() && narrow) {
      return
    }
    hoveredCards.current = [
      ...hoveredCards.current,
      {
        instanceId: event.instanceId,
        mesh: event.object,
        type: event.pointerType,
      },
    ];
    toggleMousePointerHand(true);
  };
  const hoverOffMesh = (event) => {
    if (narrow && inTimeline()) {
      return
    }
    if (
      hoveredClose.current ||
      hoveredLink.current ||
      event.pointerType === "touch"
    ) {
      return;
    }
    const i = hoveredCards.current.findIndex(
      (card) =>
        card.instanceId === event.instanceId && card.mesh === event.object
    );
    const newCards = [...hoveredCards.current];
    const cardToRemove = newCards.splice(i, 1);
    if (cardToRemove[0] && cardToRemove[0].instanceIndex != null) {
      instancedMeshGroups.current[cardToRemove[0].instanceIndex][cardToRemove[0].instanceId].userData.hovered = false
    }
    if (newCards.length === 0) {
      toggleMousePointerHand(false);
    }
    hoveredCards.current = newCards;
  };
  const hoverOffCardMeshes = () => {
    hoveredClose.current = false;
    hoveredLink.current = false;
  };
  const hoverOffAllMeshes = () => {
    hoveredCards.current = [];
    hoverOffCardMeshes();
  };

  const clickScene = (event) => {
    if (hoveredLink.current) {
      return;
    }
    if (clickedMonth.current) {
      clickedMonth.current = false
      return
    }
    if (hoveredCards.current.length === 0 || hoveredClose.current) {
      unclickCards();
      return;
    }
    const lastCard = hoveredCards.current[hoveredCards.current.length - 1];
    if (!clickedCards.includes(lastCard)) {
      const instanceRefIndex = instancedMeshRefs.current
        .filter((d) => !!d)
        .findIndex((d) => d === lastCard.mesh);
      if (instanceRefIndex !== -1) {
        lastCard.instanceIndex = instanceRefIndex;
        clearOpenCardTimeouts();
        toggleClickToOpenTooltip(false);
        toggleTimelineTooltip(false);
        openCard(lastCard);
        hoverOffAllMeshes();
      }
    }
  };

  const hoverMilestone = (milestoneIndex) => (event) => {
    const milestoneRef = milestoneRefs.current[milestoneIndex];
    if (!milestoneRef || milestoneIndex === 0) {
      return;
    }
    if (milestoneRef.lineTween) {
      milestoneRef.lineTween.stop();
    }
    milestoneRef.lineTween = new TWEEN.Tween(milestoneRef.children[1].material)
      .to({ opacity: 1 }, 100)
      .start();
    if (milestoneRef.textTween) {
      milestoneRef.textTween.stop();
    }
    milestoneRef.textTween = new TWEEN.Tween(milestoneRef.children[2].material)
      .to({ opacity: 1 }, 100)
      .delay(100)
      .start();
    setHoveredMilestoneIndex(milestoneIndex);
    toggleMousePointerHand(true);
  };
  const hoverOffMilestone = (milestoneIndex) => (event) => {
    const milestoneRef = milestoneRefs.current[milestoneIndex];
    if (!milestoneRef || milestoneIndex === 0) {
      return;
    }
    if (milestoneRef.lineTween) {
      milestoneRef.lineTween.stop();
    }
    milestoneRef.lineTween = new TWEEN.Tween(milestoneRef.children[1].material)
      .to({ opacity: 0 }, 100)
      .start();
    if (milestoneRef.textTween) {
      milestoneRef.textTween.stop();
    }
    milestoneRef.textTween = new TWEEN.Tween(milestoneRef.children[2].material)
      .to({ opacity: 0 }, 100)
      .start();
    setHoveredMilestoneIndex(null);
    toggleMousePointerHand(false);
  };

  const [lineWidth, setLineWidth] = useState(0);

  const lineBufferGeometry = useMemo(
    () =>
      new BufferGeometry().setFromPoints([
        new Vector3(-lineWidth / 2, 0, 0),
        new Vector3(lineWidth / 2, 0, 0),
      ]),
    [lineWidth]
  );
  const lineBufferGeometryBackwards = useMemo(
    () =>
      new BufferGeometry().setFromPoints([
        new Vector3(0, 0, 0),
        new Vector3(-lineWidth, 0, 0),
      ]),
    [lineWidth]
  );

  const [mobileTouchedMonth, setMobileTouchedMonth] = useState(null)
  const clickMonth = (month) => () => {
    if (isCardHovered) {
      return
    }
    if (hoveredLink.current) {
      return;
    }
    if (openingPrevOrNext.current) {
      return
    }
    clickedMonth.current = true
    setMobileTouchedMonth(month)
    const newCard = getRandomCard(
      instancedMeshGroups.current,
      (item) => {
        return (
          item.userData.dataRow.date.getMonth() === month.date.getMonth() &&
          item.userData.dataRow.date.getFullYear() === month.date.getFullYear()
          // && item.userData.dataRow.tweet_text && item.userData.dataRow.tweet_text.length > 180
          // && item.userData.dataRow.redacted && item.userData.dataRow.source_unavailable === 'True'
          // && item.userData.dataRow.type === 'media'
        );
      }
    );
    openCard(newCard);
  }
  return (
    <div
      ref={container}
      className="ThreeScene"
      onClick={clickScene}
      style={{ height }}
    >
      <Canvas
        linear
        width={width}
        height={height}
        dpr={window.devicePixelRatio}
        camera={{
          position: [0, 0, 500],
          near: 100,
          far: 10000,
          fov: 75,
        }}
        className="canvas"
        gl={{ alpha: false }}
      >
        <color attach="background" args={[0x0b0b0b]} />

        <Updater />
        <PositionTextFunction
          data={data}
          width={width}
          height={height}
          inTimeline={inTimeline}
          isFullscreen={isFullscreen}
          selectedTimelineAttributeIndex={selectedTimelineAttributeIndex}
        />
        {textTextureMaterials.map((material, materialIndex) => {
          const items = instancedMeshGroups.current[materialIndex];
          const count = items ? items.length : 0;
          if (count === 0) {
            return null;
          }
          return (
            <instancedMesh
              onPointerOver={hoverMesh}
              onPointerDown={hoverMesh}
              onPointerOut={hoverOffMesh}
              key={materialIndex}
              ref={(node) => (instancedMeshRefs.current[materialIndex] = node)}
              args={[null, null, count]}
              material={material}
              onUpdate={(mesh) => {
                mesh.setColorAt(0, threeWhite);
              }}
            >
              <planeBufferGeometry args={[6, 6]} />
            </instancedMesh>
          );
        })}
        {monthLabelData.map((month, i) => {
          return (
            <group
              ref={(node) => (timelineLabelRefs.current[i] = node)}
              key={month.date.toLocaleString()}
              userData={month}
              position={[0, -height * 2, 0]}
            >
              <line position={[0, 1, 0]} geometry={lineBufferGeometry}>
                <lineBasicMaterial color={0xffffff} />
              </line>
              <mesh
                scale={[0.5, 0.5, 0.5]}
                position={[
                  month.material.map.image.width / 4 - lineWidth / 2,
                  -11,
                  0,
                ]}
                material={month.material}
                geometry={month.geometry}
              />
              {narrow ? <mesh onClick={clickMonth(month)}>
                <planeBufferGeometry args={[width * 2, 1]} />
                <meshBasicMaterial color={'black'} />
              </mesh> : null }
              {narrow ?
                <mesh
                  scale={[0.5, 0.5, 0.5]}
                  position={[
                    timelineButtonLabel.material.map.image.width / 4 - lineWidth / 2,
                    -34,
                    0,
                  ]}
                  material={timelineButtonLabel.material}
                  geometry={timelineButtonLabel.geometry}
                /> : null}
            </group>
          );
        })}
        {milestoneTextures.map((milestone, milestoneIndex) => {
          const visible = milestoneIndex === 0;
          const opacity = visible ? 1 : 0;

          const showHoveredDot =
            milestoneIndex === 0 || milestoneIndex === hoveredMilestoneIndex;
          const material = showHoveredDot
            ? namedMaterials.milestoneHovered
            : namedMaterials.milestone;
          const groupScale = pMap(height, 700, 1000, 1.5, 1, true);
          return (
            <group
              scale={[groupScale, groupScale, groupScale]}
              position={[lineWidth / 2, -height * 2, 0]}
              key={milestoneIndex}
              ref={(node) => (milestoneRefs.current[milestoneIndex] = node)}
            >
              <mesh
                onPointerEnter={hoverMilestone(milestoneIndex)}
                onPointerLeave={hoverOffMilestone(milestoneIndex)}
                material={material}
              >
                <planeBufferGeometry args={[15, 15]} />
              </mesh>
              <line
                geometry={lineBufferGeometryBackwards}
                onUpdate={(line) => line.computeLineDistances()}
              >
                <lineDashedMaterial
                  opacity={opacity}
                  transparent={true}
                  dashSize={5}
                  gapSize={5}
                  color={0xe0b72d}
                />
              </line>
              <mesh
                scale={[0.5, 0.5, 0.5]}
                position={[
                  -milestone.material.map.image.width / 2 / 2 - 9,
                  14,
                  0,
                ]}
                material={milestone.material}
                geometry={milestone.geometry}
              />
            </group>
          );
        })}
        <group position={[0, 0, 2]} ref={timelineColumnHeaderGroupRef}>
          {timelineColumnHeaders.map((header, headerIndex) => {
            const textureWidth = header.material.map.image.width / 2;
            let x =
              headerIndex === 0
                ? -lineWidth / 2 + textureWidth / 2
                : -width / 5 +
                  (headerIndex === 1
                    ? -textureWidth / 2 - (narrow ? 2.5 : 4)
                    : textureWidth / 2 + (narrow ? 2.5 : 4));
            return (
              <mesh
                scale={[0.5, 0.5, 0.5]}
                position={[x, 16, 0]}
                material={header.material}
                geometry={header.geometry}
                key={headerIndex}
              />
            );
          })}
          <mesh position={[0, 300, 0]}>
            <meshBasicMaterial color={0x0b0b0b} />
            <planeBufferGeometry args={[width * 2, 600]} />
          </mesh>
          <line geometry={lineBufferGeometry}>
            <meshBasicMaterial color={0xffffff} />
          </line>
        </group>
        {clickedCards.map((d, clickedCardIndex) => {
          return (
            <primitive onPointerEnter={overCard} onPointerLeave={offCard} onPointerDown={overCard} object={d.textMesh} key={d.textMesh.uuid}>
              {d.textMesh.userData.children.map((c) => (
                <mesh
                  key={c.mesh.uuid}
                  material={c.material}
                  geometry={c.geometry}
                  position={[c.userData.x || 0, c.userData.y || 0, 0]}
                  onPointerOver={c.userData.onPointerOver}
                  onPointerOut={c.userData.onPointerOut}
                  // onPointerOver={() => {}}
                  // onPointerOut={() => {}}
                  onClick={c.userData.onClick}
                  renderOrder={999}
                />
              ))}
            </primitive>
          );
        })}
        {clickToOpenTooltip ? (
          <mesh
            scale={[0, 0, 0]}
            position={[0, 180, 0]}
            ref={(node) => (clickToOpenTooltip.ref = node)}
            material={clickToOpenTooltip.material}
            geometry={clickToOpenTooltip.geometry}
          />
        ) : null}
        {timelineTooltip ? (
          <mesh
            scale={[0, 0, 0]}
            position={[0, 100, 0]}
            ref={(node) => (timelineTooltip.ref = node)}
            material={timelineTooltip.material}
            geometry={timelineTooltip.geometry}
          />
        ) : null}
      </Canvas>
      <Header
        introStepIndex={introStepIndex}
        isFullscreen={isFullscreen}
        clickFullScreenButton={clickFullScreenButton}
      />
      {content}
      {
        // <Navigation
        //   steps={steps}
        //   stepRefs={timelineDivs.current}
        //   stepIndex={stepIndex}
        //   open={showNav}
        // />
      }
      {/* <div style={{ position: 'fixed', top: 0, left: 120, zIndex: 100, color: 'red' }}>{window.document.body.style.overflow} | {window.innerHeight} | {document.documentElement.clientHeight} | {window.scrollY}</div> */}
      <div
        className={classnames(
          "fullscreenCopy fullscreenCopyBottom showScrollArrow",
          { showScrollToContinue: !!showScrollToContinue },
          showScrollToContinue
        )}
      >
        <span>{`${touchScreen ? "Swipe" : "Scroll"} to continue`}</span>
        <div
          onClick={() => {
            objectToScroll.scrollTo({
              top: objectToScroll[currentScrollProperty] + window.innerHeight,
              behavior: "smooth",
            });
          }}
          className="arrow"
        />
      </div>
      <Intro
        positions={positions}
        positionIndex={positionIndex}
        introStepIndex={introStepIndex}
        setIntroStepIndex={setIntroStepIndex}
        setPositionOverrides={setPositionOverrides}
      />
      <img
        src={twitterImagePlaceholder}
        alt="twitter"
        style={{
          opacity: 0,
          pointerEvents: "none",
          position: "absolute",
          top: 0,
        }}
        id="twitterImage"
      />
      <img
        src={sharrow}
        alt="linkImage"
        style={{
          opacity: 0,
          pointerEvents: "none",
          position: "absolute",
          top: 0,
        }}
        id="linkImage"
      />
      <img
        src={cardClose}
        alt="closeImage"
        style={{
          opacity: 0,
          pointerEvents: "none",
          position: "absolute",
          top: 0,
        }}
        id="closeImage"
      />
      <img
        src={previousArrow}
        alt="previousArrow"
        style={{
          opacity: 0,
          pointerEvents: "none",
          position: "absolute",
          top: 0,
        }}
        id="previousArrow"
      />
      <img
        src={nextArrow}
        alt="nextArrow"
        style={{
          opacity: 0,
          pointerEvents: "none",
          position: "absolute",
          top: 0,
        }}
        id="nextArrow"
      />
    </div>
  );
}
