import { csvParse } from "d3-dsv";

const milestones = csvParse(`Date,Text,URL,Notes,Include
1/21/2020,First case of coronavirus confirmed in the United States.,https://www.nytimes.com/article/coronavirus-timeline.html,https://www.who.int/emergencies/diseases/novel-coronavirus-2019/interactive-timeline?gclid=CjwKCAjw07qDBhBxEiwA6pPbHhwTGHVWIk9kCVV6umDbAmYxizqhrNvimPtXaeQsPag_dn0tdzAm2hoCQ8cQAvD_BwE#!,TRUE
2/11/2020,World Health Organization officially names novel coronavirus disease COVID-19.,https://www.history.com/this-day-in-history/world-health-organization-officially-names-coronavirus-covid19,,TRUE
3/20/2020,Most states rollout mandatory stay-at-home orders.,https://www.cdc.gov/mmwr/volumes/69/wr/pdfs/mm6935a2-H.pdf,,TRUE
4/30/2020,US unemployment rate rises to record high of 14.7%.,"https://www.bls.gov/opub/ted/2020/unemployment-rate-rises-to-record-high-14-point-7-percent-in-april-2020.htm?view_full#:~:text=Unemployment%20rate%20rises%20to%20record%20high%2014.7%20percent%20in%20April%202020,-May%2013%2C%202020&text=This%20is%20the%20highest%20rate,to%2023.1%20million%20in%20April.",,TRUE
5/28/2020,"US COVID-19 deaths exceed 100,000.",https://www.ajmc.com/view/a-timeline-of-covid19-developments-in-2020,,TRUE
6/10/2020,US COVID-19 cases reach 2 million.,https://www.ajmc.com/view/a-timeline-of-covid19-developments-in-2020,,TRUE
7/14/2020,Early medical trials point to vaccine’s efficacy.,https://www.ajmc.com/view/a-timeline-of-covid19-developments-in-2020,,TRUE
8/23/2020,The US Food and Drug Administration issues an emergency use authorization for plasma to be used in COVID-19 treatments. ,https://www.fda.gov/news-events/press-announcements/fda-issues-emergency-use-authorization-convalescent-plasma-potential-promising-covid-19-treatment,,TRUE
9/22/2020,"US COVID-19 deaths exceed 200,000.",https://www.npr.org/sections/coronavirus-live-updates/2020/09/22/911934489/enormous-and-tragic-u-s-has-lost-more-than-200-000-people-to-covid-19,,TRUE
10/2/2020,President Trump and the First Lady Melania Trump test positive for COVID-19.,https://www.nytimes.com/interactive/2020/10/02/us/politics/trump-contact-tracing-covid.html,,TRUE
11/4/2020,"The US is the first country in the world to exceed 100,000 daily cases of COVID-19.",https://www.wsj.com/livecoverage/covid-2020-11-05,,TRUE
12/8/2020,First person in the world receives COVID-19 vaccine.,https://www.bbc.com/news/uk-55227325,,TRUE
1/28/2021,A new variant of COVID-19 from South Africa is detected in the US for the first time.  ,https://apnews.com/article/coronavirus-variant-south-africa-us-2bc397370cdd44afe916ddd6edbaf870,,TRUE
2/22/2021,"US COVID-19 deaths exceed 500,000.",https://www.nytimes.com/2021/02/22/us/us-covid-deaths-half-a-million.html,,TRUE
3/11/2021,President Biden signs $1.9 trillion COVID-19 economic relief package into law. ,https://www.cnn.com/2021/03/11/politics/biden-sign-covid-bill/index.html,,TRUE
4/22/2021,US Senate passes bill to combat Anti-Asian hate crimes. ,https://www.nytimes.com/2021/04/22/us/politics/senate-anti-asian-hate-crimes.html,,TRUE
5/3/2021,The Asian American Foundation launches with $250 million in backing from influential executives and major companies.,https://www.nytimes.com/2021/05/03/business/dealbook/asian-american-donation-philanthropy.html,,TRUE
6/6/2021,US surpasses 300 million COVID-19 vaccine shots administered.,https://abcnews.go.com/US/us-surpasses-300-million-covid-19-vaccine-shots/story?id=78119122,,TRUE
7/29/2021,President Biden announces vaccine-or-mask mandate for federal employees.,https://www.govexec.com/workforce/2021/07/biden-announces-vaccine-or-masks-mandate-feds-some-contractors/184161,,TRUE
8/25/2021,A US intelligence report into the origins of the coronavirus pandemic is inconclusive.,https://www.cnbc.com/2021/08/27/covid-origin-report-us-intelligence-agencies-are-divided.html,,TRUE
9/28/2021,TAAF announces three Actions Centers as a part of a Anti-Hate National Network.,https://www.prnewswire.com/news-releases/taaf-announces-its-anti-hate-national-network-including-the-launch-of-aapi-action-centers-in-chicago-new-york-city-and-oakland-301386534.html,,TRUE
10/22/2021,DOJ reports a Utah school district ignored hundreds of racial harassment complaints against Black and Asian American students.,https://www.cnn.com/2021/10/22/us/utah-school-district-ignored-racial-harassment-doj/index.html,,TRUE
11/3/2021,Michelle Wu sworn in as Boston’s first Asian American mayor.,https://www.pbs.org/newshour/politics/michelle-wu-sworn-in-as-bostons-first-woman-and-asian-american-mayor,,TRUE
12/28/2021,Cases of the Omicron variant surge across cities in the United States.,https://www.cnn.com/2021/12/28/tech/apple-store-new-york-closures/index.html,,TRUE
1/18/2022,N.J. mandates teaching Asian American and Pacific Islander history in schools.,https://www.nbcnews.com/news/asian-america/nj-mandates-teaching-asian-american-pacific-islander-history-schools-rcna12637,,TRUE
2/10/2022,Nathan Chen wins gold in men's figure skating at the 2022 Winter Olympics in Beijing.,https://www.npr.org/2022/02/10/1079777978/nathan-chen-wins-gold-figure-skating-olympics,,TRUE
3/16/2022,Rally against anti-Asian violence held in Times Square one year after the Atlanta shootings.,https://nypost.com/2022/03/16/rally-against-anti-asian-violence-held-in-times-square-year-after-atlanta-spa-shootings/,,TRUE
4/19/2022,Federal mask mandate for travelers no longer in effect,https://www.cnn.com/us/live-news/federal-mask-mandate-airlines-04-19-22/index.html,,TRUE
5/23/2022,Twitter features Decoding Hate project on Behind the Code blog post series,https://developer.twitter.com/en/community/success-stories/decoding-hate?ref=os-tw-decoding-hate&utm_source=tw&utm_medium=os&utm_campaign=decoding-hate,,TRUE
`).filter((d) => d.Include === "TRUE");

milestones.forEach((milestone) => {
  const d = milestone.Date;
  const [month, day, year] = d.split("/");
  milestone.dateString = d;
  milestone.Date = new Date(year, month - 1, day);
});

export default milestones;
