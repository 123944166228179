
import { Mesh, MeshBasicMaterial, Texture, PlaneBufferGeometry, DoubleSide, LinearFilter } from 'three'
const canvasGeometries = {}
const textureLimit = 100000
const textures = []

let birdPlaceholder = null
let linkPlaceholder = null
let closePlaceholder = null
let previousArrowPlaceholder = null
let nextArrowPlaceholder = null

// const mix = (a, b, t) => a * (1 - t) + b * t
// const randRange = (min, max) => {
//   return min + (max - min) * Math.random()
// }

const minWidth = 720; // default minWidth for isCard items
const tooltipMinWidth = 640
const linkImageSize = 48;
const arrowSize = 40

export const closeImageSize = 36;

const nextPowerOfTwo = (x) => {
  let power = 1;
  while(power < x)
    power*=2;

  return power
}

export function generateTextureCanvas(text = '', textSize, _options = {}) {
  let defaultOptions = {
    opacity: 1,
    weight: '',
    hue: 0xffffff,
    split: false,
    font: 'sans-serif',
    padding: 0,
    textFill: 'rgb(255, 255, 255)',
    backgroundFill: null,
    alignText: '',
    drawAttributes: null,
    stroke: null,
    date: null,
    drawTwitterHeader: false,
    drawNewsHeader: false,
    isCard: false,
    isMilestone: false,
    isTarget: false,
    width: null,
    height: null,
    mediaSource: '',
    mediaLocation: '',
    showCloseButton: false,
    isTooltip: false,
    showCensoredTextLink: false,
    delimiter: null,
    underline: false,
    showArrowButtons: false,
  }
  if (text === '') {
    debugger
  }
  let options = {...defaultOptions}

  Object.keys(options).forEach(option => {
    if (option in _options) {
      options[option] = _options[option]
    }
  })

  let texture = null
  let rectHeight = 0;
  if (textures.length < textureLimit) {
    const stroke = 'rgb(0, 0, 0)'
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    let width = 72;
    if (options.split) {
      let maxWidth = context.measureText(text).width;
      if (options.drawNewsHeader) {
        context.font = `bold ${textSize * 1.75}pt BeausiteClassicWebBold`;
        maxWidth = Math.max(maxWidth, linkImageSize + context.measureText(options.mediaLocation).width);
        context.font = `${options.weight} ${textSize}pt ${options.font}`;
      }
      width = Math.ceil(Math.sqrt(maxWidth)) * textSize / 1.9
      width *= 2

    } else {
      context.font = `${options.weight} ${textSize}pt ${options.font}`;
      width = context.measureText(text).width
      width = nextPowerOfTwo(width)
    }
    if (options.isCard) {
      width = Math.max(width, options.isTooltip ? tooltipMinWidth : minWidth);
      const maxWidthFromWindow = window.innerWidth * 2 - (10 * 2 * 2)// 10 px from both sides * 2x multiplier
      width = Math.min(maxWidthFromWindow, width)
    }
    if (options.width) {
      if (options.isTarget) {
        width = options.width;
      } else {
        width = options.width * 2; // scale up to avoid blurry assets
      }
    }

    let height = options.height || width

    canvas.width = width + 1;
    if (options.isCard) {
      // canvas.width += 40;
    }

    if (options.isCard && options.showArrowButtons) {
      height += arrowSize * 4
    }
    // console.log(width, height, text)
    canvas.height = height;
    // context.clearRect(0, 0, width + 1, height);
    context.clearRect(0, 0, canvas.width, height);
    // context.fillStyle ='red'
    // context.fillRect(0, 0, width, height)
    context.font = `${options.weight} ${textSize}pt ${options.font}`;
    context.fillStyle = options.textFill;
    // console.log(fill)
    // console.log(textSize)
    // const jitterSize = width * 0.05

    if (options.backgroundFill) {
      // options.padding = 40
      options.padding = options.isMilestone || options.isTooltip ? 10 : 40
      // if (options.isMilestone) {
        // width += options.padding * 2;
      // }
    }

    const { drawTwitterHeader, drawNewsHeader } = options
    const headerHeight = drawTwitterHeader || drawNewsHeader ? 120 : 0

    if (options.isTarget) {
      // canvas.width = options.width
      // canvas.height = options.height
      // const padding = 20;
      // const width = options.width - padding * 2;
      // const x = padding;
      // const y = (width - options.height) / 2;
      // const height = headerHeight
      context.fillStyle = options.backgroundFill
      context.beginPath()
      // context.rect(x, y, width, height)
      context.rect(0, 0, options.width, options.height);
      context.closePath()
      context.fill()

    } else if (options.split) {
      const lines = []
      if (options.delimiter) {
        const splitText = text.split(options.delimiter);
        splitText.forEach(line => lines.push(line));
      } else {
        const splitText = text.split(' ');
        let lineSoFar = '';
        let newlineWidth = width - options.padding * 2
        splitText.forEach((word) => {
          let newTestLine = `${lineSoFar} ${word}`.trim()
          const textWidth = context.measureText(newTestLine).width;
          if (textWidth < newlineWidth) {
            lineSoFar = newTestLine
          } else {
            lines.push(lineSoFar)
            lineSoFar = word
          }
        });
        if (lineSoFar.trim() !== '') {
          lines.push(lineSoFar)
        }
      }
      const totalTextHeight = (lines.length + 0.5) * textSize * 1.5
      const attributeFontSize = textSize - 4

      if (options.isCard  && drawTwitterHeader) {
        const paddingHeight = options.padding * 4
        const rectHeightTest = totalTextHeight + paddingHeight  + headerHeight + (options.date ? attributeFontSize * 2 : 0)

        const overlap = rectHeightTest - height
        if (overlap > 0) {
          height = height + overlap
          canvas.height = height
          context.clearRect(0, 0, canvas.width, height);
        }
      }
      // const startY = height / 2 - totalTextHeight / 2
      const startY = options.isMilestone
        ? height / 2 - (lines.length + 0.5) * (textSize * 1.5) + (options.padding * 2.5)
        : height / 2 - totalTextHeight / 2
      let bottomY = startY + totalTextHeight


      let attributes = []
      let maxAttributeY = bottomY - ((options.isCard && !options.isTooltip) ? attributeFontSize : 0) + (options.showCensoredTextLink ? attributeFontSize * 2 : 0)
      if (options.drawAttributes) {
        let x = width - options.padding
        let attributeY = bottomY + attributeFontSize + 2 + (options.showCensoredTextLink ? attributeFontSize * 2 : 0)

        context.font = `${options.weight} ${attributeFontSize}pt ${options.font}`;

        attributes = options.drawAttributes.map(({label, textFill, dotFill}) => {
          const textWidth = context.measureText(label).width
          const dotSize = 7
          const dotPadding = dotSize * 2
          const itemPadding = 20

          let textX = x - textWidth
          let dotX = textX - dotPadding - dotSize / 2
          if (dotX + dotSize < options.padding) {
            x = width - options.padding
            attributeY += attributeFontSize + 8
            textX = x - textWidth
            dotX = textX - dotPadding - dotSize / 2
          }
          x -= textWidth + dotPadding + dotSize * 2 + itemPadding
          maxAttributeY = Math.max(attributeY, maxAttributeY)
          return {
            dotX, textX, attributeY, label, textFill, dotFill, dotSize
          }
        })
      }
      // const { drawTwitterHeader } = options
      // const headerHeight = drawTwitterHeader ? 120 : 0

      if (options.backgroundFill) {
        context.fillStyle = options.backgroundFill

        context.fillStyle = options.backgroundFill
        // context.fillRect(0, startY - options.padding, width, totalTextHeight + options.padding * 2 + (maxAttributeY - bottomY))
        // const x = options.isMilestone ? -options.padding : 0
        const x = 0
        const y = options.isMilestone
          ? startY - options.padding / 2
          : startY - options.padding - headerHeight
        const paddingHeight = options.isMilestone ? 0 : options.padding * 2
        const height = totalTextHeight + paddingHeight + (maxAttributeY - bottomY) + headerHeight + (options.date ? attributeFontSize * 2 : 0)
            + (options.showArrowButtons ? arrowSize * 0.5 : 0)
        rectHeight = height;
        const radius = 20
        const fill = true
        const stroke = false
        // context.lineWidth = 5
        context.strokeStyle = options.stroke
        // console.log(options.stroke)
        context.shadowBlur = options.isMilestone ? 0 : 15
        // context.shadowBlur = 15
        context.shadowColor = '#000000'
        if (options.isMilestone) {
          roundRect(context, x, y, width + options.padding, height, 0, fill, stroke)
        } else {
          roundRect(context, x + context.shadowBlur, y, width - context.shadowBlur * 2, height, radius, fill, stroke)
        }
        context.shadowBlur = 0
        context.fillStyle = options.textFill
      }

      context.font = `${options.weight} ${textSize}pt ${options.font}`;

      lines.forEach((line, lineCount) => {

        // const y = (lineCount + 1) * textSize * 1.5 + startY
        const y = options.isMilestone
          ? ((lineCount + 0.825) * (textSize * 1.5)) + startY
          : (lineCount + 1) * (textSize * 1.5) + startY
        let x = 0
        let alignText = options.isTooltip ? 'center' : options.isMilestone ? 'right' : 'left'
        if (alignText === 'center') {
          const lineWidth = context.measureText(line).width
          x = width / 2 - lineWidth / 2
        } else if (alignText === 'left') {
          x = options.padding
        } else if (alignText === 'right') {
          const lineWidth = context.measureText(line).width
          x = width - options.padding - lineWidth
        }
        context.fillText(line, x, y);
      })


      context.font = `${options.weight} ${attributeFontSize}pt ${options.font}`;
      let dateHeight = 0
      if (options.date) {
        dateHeight = attributeFontSize * 2.2
        context.fillText(options.date, options.padding, startY + totalTextHeight + attributeFontSize * 1.2 + 2)
      }

      if (options.showCensoredTextLink) {
        const censoredText = 'This Tweet is no longer available'
        context.fillText(censoredText, options.padding, startY + totalTextHeight + attributeFontSize * 1.2 + 2 + dateHeight)

      }
      if (options.showArrowButtons) {
        if (!previousArrowPlaceholder) {
          previousArrowPlaceholder = document.getElementById('previousArrow')
          nextArrowPlaceholder = document.getElementById('nextArrow')
        }
        if (previousArrowPlaceholder) {
          context.drawImage(
            previousArrowPlaceholder, width - arrowSize * 6,
            startY + totalTextHeight + attributeFontSize * 1.2 + 2 +
              dateHeight + (options.showCensoredTextLink ? attributeFontSize * 2 : 0) + arrowSize * 1.0,
            arrowSize * 2, arrowSize * 2
          )
          context.drawImage(
            nextArrowPlaceholder,
            width - arrowSize * 2.5,
            startY + totalTextHeight + attributeFontSize * 1.2 + 2 +
              dateHeight + (options.showCensoredTextLink ? attributeFontSize * 2 : 0) + arrowSize * 1.0,
            arrowSize * 2, arrowSize * 2
          )
        }
      }
      context.font = `${options.weight} ${attributeFontSize}pt ${options.font}`;
      context.save()
      context.translate(0, dateHeight)
      attributes.forEach(({dotX, textX, attributeY, label, textFill, dotFill, dotSize}) => {
          context.fillStyle = dotFill
          context.fillText(label, textX, attributeY)

          context.fillStyle = dotFill
          context.beginPath()
          context.arc(dotX, attributeY - 10, dotSize, 0, Math.PI * 2, false)
          context.closePath()
          context.fill()
      })
      context.restore()

      if (drawTwitterHeader) {
        const placeholderFillColor = '#E2E8F0'
        context.fillStyle = placeholderFillColor

        // user profile image placeholder
        const profilePictureSize = 80
        const profilePictureRadius = profilePictureSize/2
        context.beginPath()
        context.arc(options.padding + profilePictureRadius, startY - headerHeight + profilePictureRadius, profilePictureRadius, 0, Math.PI * 2, false)
        context.closePath()
        context.fill()
        // context.fillRect(options.padding, startY - headerHeight, profilePictureSize, profilePictureSize)
        const imagePadding = 10
        const lineHeight = textSize * 1.5

        // username placeholder
        const maxLength = width - profilePictureSize - options.padding * 4 - imagePadding
        const usernameLength = Math.min(maxLength, Math.random() * 200 + 150)
        roundRect(context, options.padding + profilePictureSize + imagePadding, startY - headerHeight , usernameLength, lineHeight, 9, placeholderFillColor, false)

        // user handle placeholder
        const handleLength = Math.min(maxLength, Math.random() * 150 + 150)
        roundRect(context, options.padding + profilePictureSize + imagePadding, startY - headerHeight + lineHeight * 1.2, handleLength, lineHeight, 9, placeholderFillColor, false)

        if (!birdPlaceholder) {
          birdPlaceholder = document.getElementById('twitterImage')
        }

        if (birdPlaceholder) {
          const twitterW = profilePictureSize
          const twitterH = twitterW * 19.5/24
          context.drawImage(birdPlaceholder, width - twitterW - options.padding - closeImageSize * 1.5, startY - headerHeight, twitterW, twitterH)
        }
      }

      if (drawNewsHeader) {
        // const linkImageSize = 48;
        const lineHeight = textSize * 1.5
        context.fillStyle = 'black'
        context.font = `${options.weight} ${textSize}pt ${options.font}`;
        context.fillText(`Source: `, options.padding, startY - headerHeight + lineHeight * 0.75);
        context.font = `bold ${textSize}pt BeausiteClassicWebBold`;
        context.fillText(`${options.mediaSource}`, options.padding * 4, startY - headerHeight + lineHeight * 0.75);
        context.font = `${options.weight} ${textSize}pt ${options.font}`;

        const mediaString = fittingString(context, options.mediaLocation, width - options.padding * 2)
        context.fillText(mediaString, options.padding, startY - headerHeight + lineHeight * 2.25);

        if (!linkPlaceholder) {
          linkPlaceholder = document.getElementById('linkImage');
        }
        if (linkPlaceholder) {
          const twitterW = linkImageSize
          const twitterH = linkImageSize
          context.drawImage(linkPlaceholder, width - twitterW - options.padding - closeImageSize * 1.5, startY - headerHeight, twitterW, twitterH)
        }
      }

      if (options.isCard && options.showCloseButton) {
        if (!closePlaceholder) {
          closePlaceholder = document.getElementById('closeImage');
        }
        if (closePlaceholder) {
          const closeW = closeImageSize
          const closeH = closeImageSize
          context.drawImage(closePlaceholder,
            width + options.padding * 0 - closeW * 2,
            startY - (headerHeight + options.padding * 0.5),
            closeW, closeH)
          // context.drawImage(closePlaceholder, width - options.padding * 0.5, startY - (headerHeight + options.padding * 2.25), twitterW, twitterH)
        }
      }


    } else {
      // const textWidth = context.measureText(text).width;
      context.strokeStyle = stroke;
      context.lineWidth = 6;
      // context.strokeText(text, (width / 2) - (textWidth / 2), (height / 2) + (textSize / 2.25));
      context.fillStyle = options.textFill;
      // context.fillText(text, (width / 2) - (textWidth / 2), (height / 2) + (textSize / 2.25));
      let x = 0;
      let textWidth = context.measureText(text).width
      if (options.alignText === 'end') {
        x = width - textWidth
      }
      const y = height / 2 - textSize / 2 + textSize
      // const y = textSize
      if (options.backgroundFill) {
        context.fillStyle = options.backgroundFill
        context.fillRect(x, y - textSize - 10, textWidth, textSize + 20)
        context.fillStyle = options.textFill;
      }
      context.fillText(text, x, y);


      if (options.underline) {
        context.save()
        context.strokeStyle = context.fillStyle
        context.lineWidth = 2
        context.beginPath()
        context.moveTo(x, y + 3)
        context.lineTo(x + textWidth, y + 3)
        context.stroke()
        context.restore()
      }

    }
    // context.strokeStyle = 'rgba(255, 0, 0, 0.5)'
    // context.strokeRect(0, 0, width, height)
    if (options.backgroundFill) {
      // context.fillRect(0, 0, width, height)
    }
    // document.body.appendChild(canvas)

    texture = new Texture(canvas);
    if (!options.split) {
      texture.minFilter = LinearFilter
      // texture.magFilter = NearestFilter
    }
    texture.needsUpdate = true;
    textures.push(texture)
  } else {
    texture = textures[~~(Math.random() * textures.length)]
  }
  const opacity = options.isTooltip ? 0 : 1
  const material = new MeshBasicMaterial({
    map: texture,
    side: DoubleSide,
    transparent: true,
    // depthTest: false,
    opacity,
    // color: options.hue,
  });

  const { width, height } = texture.image

  const geometryKey = `${width}-${height}`
  if (!canvasGeometries[geometryKey]) {
    canvasGeometries[geometryKey] = new PlaneBufferGeometry(width, height);
  }
  const geometry = canvasGeometries[geometryKey]
  const mesh = new Mesh(geometry, material);
  // mesh.userData.transform = new Vector3();
  const userData = {
    onPointerOver: null, // () => {}
    onPointerOut: null, // () => {}
    onClick: null, // () => {}
  };
  return {
    mesh, geometry, material, texture, width, rectHeight, userData
  }
}

export default generateTextureCanvas;

function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = {tl: radius, tr: radius, br: radius, bl: radius};
  } else {
    var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
}

function fittingString(c, str, maxWidth) {
  var width = c.measureText(str).width;
  var ellipsis = '…';
  var ellipsisWidth = c.measureText(ellipsis).width;
  if (width<=maxWidth || width<=ellipsisWidth) {
      return str;
  } else {
      var len = str.length;
      while (width>=maxWidth-ellipsisWidth && len-->0) {
          str = str.substring(0, len);
          width = c.measureText(str).width;
      }
      return str+ellipsis;
  }
}
