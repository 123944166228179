import taafLogo from "../images/TAAF-logo-no-name.svg";
import classnames from "classnames";
import Share from "./About/Share";
import "./Header.scss";
import "./About/Share.scss";

export default function Header(props) {
  const { introStepIndex, isFullscreen, clickFullScreenButton, showAbout } =
    props;

  const visible = introStepIndex === null || introStepIndex >= 4; // introStep 4 is when title is shown
  return (
    <header className={classnames({ visible, showAbout })}>
      <a href="https://www.taaf.org/" target="_blank" rel="noopener noreferrer">
        <img src={taafLogo} alt="The Asian American Foundation" />
      </a>
      <div className="buttons">
        <Share />
        <div
          className="share"
          // className={classnames("button fullscreen", { isFullscreen })}
          onClick={clickFullScreenButton}
        >
          <div
            className={classnames("button fullscreen", { isFullscreen })}
            onClick={clickFullScreenButton}
          ></div>
        </div>
      </div>
    </header>
  );
}
