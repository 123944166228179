import { useEffect, useState } from 'react';
import { ResizeObserver } from '@juggle/resize-observer'

import ThreeScene from './ThreeScene';

import './App.scss';

import { csv } from 'd3-fetch'

window.ResizeObserver = window.ResizeObserver || ResizeObserver;
const useDevData = false
const devData = false
// const devData = process.env.NODE_ENV === "development" ? useDevData : false;
function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    let tweets = null
    let media = null
    let fonts = null

    csv(`${process.env.PUBLIC_URL}/data/tweets${devData ? '_dev' : ''}.csv`)
      .then(_tweets => {
        tweets = _tweets
        checkIfLoaded()
      })
    csv(`${process.env.PUBLIC_URL}/data/media${devData ? '_dev' : ''}.csv`)
      .then(_media => {
        media = _media
        checkIfLoaded()
      })

    const checkIfLoaded = () => {
      if (tweets && media && fonts) {
        bothLoaded()
      }
    }


    if (document.fonts) {
      document.fonts.ready.then(() => {
        fonts = true;
        checkIfLoaded()
      });
    } else {
      fonts = true;
      checkIfLoaded()
    }

    const bothLoaded = () => {

      media.forEach(m => {
        m.type = 'media'
      })
      tweets.forEach(m => {
        m.type = 'tweet'
        m.tweet_text = m.scrubbed_tweet_text
        m.redacted = m.tweet_text.includes('▓')
      })
      const maxToShowInIntro = 5000
      const dateMap = {}
      const _data = [...media, ...tweets]
      const unredactedTweets = tweets.filter(d => !d.redacted)
      const mediaRatio = media.length / _data.length
      const tweetsRatio = tweets.length / _data.length
      const mediaToShow = mediaRatio * maxToShowInIntro
      const tweetsToShow = tweetsRatio * maxToShowInIntro
      media.forEach((m, i) => {
        m.visibleInIntro = i < mediaToShow
      })
      unredactedTweets.forEach((m, i) => {
        m.visibleInIntro = i < tweetsToShow
      })

      const attributeValues = {}
      // which attributes should we categorize values for
      const attributesToExamine = ['incident_location_type', 'incident_type', 'victim_gender']
      // some attriburtes contains multiple values e.g. "Online, Business", in these cases, let's just use the first value
      const useFirstAttributeValueAttributes = ['incident_location_type', 'incident_type', 'victim_gender']
      // assign a default value for attributes that contain an empty string
      const defaultEmptyString = {
        incident_location_type: 'Unknown',
        incident_type: 'Unknown',
        victim_gender: 'Unknown',
      }
      _data.forEach(d =>{
        const date = d.sort_date
        if (!dateMap[date]) {
          // const [month, day, year] = date.split('/')
          dateMap[date] = new Date(date)
          if (dateMap[date].getFullYear() < 2020) {
            debugger
          }
        }

        d.date = dateMap[date]
        d.isCovidRelated = d.incident_covid_related.includes('TRUE') || d.incident_covid_related.includes('true') //
        d.incident_covid_related = d.isCovidRelated ? 'Covid Related' : 'Unknown'

        attributesToExamine.forEach(attribute => {
          attributeValues[attribute] = []
          let attributeValue = d[attribute]
          if (useFirstAttributeValueAttributes.includes(attribute)) {
            attributeValue = attributeValue.split(',')[0]
            d[attribute] = attributeValue
          }
          if (defaultEmptyString[attribute] && attributeValue.trim() === '') {
            attributeValue = defaultEmptyString[attribute]
            d[attribute] = attributeValue
          }

          if (!attributeValues[attribute].includes(attributeValue)) {
            attributeValues[attribute].push(attributeValue)
          }
        })
      })
      const dataNoBadDates = _data.filter(d => !isNaN(d.date))
      dataNoBadDates.forEach((d, i) => {
        const nextIndex = i === dataNoBadDates.length - 1 ? 0 : i + 1
        const prevIndex = i === 0 ? dataNoBadDates.length - 1 : i - 1
        d.nextItem = dataNoBadDates[nextIndex]
        d.prevItem = dataNoBadDates[prevIndex]
      })
      // console.log(dataNoBadDates)
      setData(dataNoBadDates)
    }
  }, []);

  return (
    <div className='App'>
      <ThreeScene
        data={data}
      />
    </div>
  );
}

export default App;
