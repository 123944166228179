import { useEffect, useMemo } from 'react'
import taafLogo from '../images/TAAF-logo.svg'
import './Intro.scss'
import { autoplayIntro } from './'
export default function Intro(props) {
  const { introStepIndex, setPositionOverrides, setIntroStepIndex, positionIndex, positions } = props
  const titlePositionIndex = positions.findIndex(d => d.label === 'inRandomTitle')
  const offscreenSpherePositionIndex = positions.findIndex(d => d.label === 'offscreen')
  const introSteps = useMemo(() => [
    { logoOpacity: 0, logoTransform: 'translateY(30%)', duration: 300 },
    { logoOpacity: 1, duration: 2000 },
    { logoOpacity: 0, duration: 1000 },
    { logoOpacity: 0, dotsInTitle: true, newPositionIndex: titlePositionIndex, duration: 2500 },
    { logoOpacity: 0, dotsInTitle: true, newPositionIndex: titlePositionIndex, showDisclaimer: true },
    { logoOpacity: 0, dotsInTitle: true, newPositionIndex: titlePositionIndex, showDisclaimer: false, duration: 500 },
    { logoOpacity: 0, dotsInTitle: true, newPositionIndex: offscreenSpherePositionIndex, showDisclaimer: false, duration: 1 },
  ], [titlePositionIndex, offscreenSpherePositionIndex])


  useEffect(() => {
    let timeoutId = null
    if (introStepIndex !== null) {
      const introStep = introSteps[introStepIndex]
      const { duration, newPositionIndex } = introStep
      if (newPositionIndex && newPositionIndex !== positionIndex) {
        // set 1st step override to next position
        setPositionOverrides(o => ({ ...o , 0: newPositionIndex}))
      }

      if (autoplayIntro && duration) {
        timeoutId = setTimeout(() => {
          if (introStepIndex === introSteps.length - 1) {
            setIntroStepIndex(null)
          } else {
            setIntroStepIndex(index => index + 1)
          }
        }, duration)
      }
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [introSteps, introStepIndex, positionIndex, setPositionOverrides, setIntroStepIndex])

  let intro = null
  if (introStepIndex !== null) {
    const introStep = introSteps[introStepIndex]
    let logoOpacity = 0
    let logoTransform = ''
    if (introStep.logoOpacity) {
      logoOpacity = introStep.logoOpacity
    }
    if (introStep.logoTransform) {
      logoTransform = introStep.logoTransform
    }
    const disclaimerOpacity = introStep.showDisclaimer ? 1 : 0
    const logoStyle = { opacity: logoOpacity, transform: logoTransform }
    const clickIntro = () => {
      if (introStepIndex === introSteps.length - 1) {
        setIntroStepIndex(null)
      } else if (!autoplayIntro || !introSteps[introStepIndex].duration) {
        setIntroStepIndex(index => index + 1)
      }
    }
    intro = (
      <div className='intro' onClick={clickIntro}>
        <img className='logo' src={taafLogo} alt="The Asian American Foundation" style={logoStyle} />
        <div className='presents' style={{ opacity: logoOpacity, transitionDelay: introStepIndex >= 2 ? `0s` : null }}>presents</div>
        <div className='disclaimer' style={{opacity: disclaimerOpacity}}>
          {/*<h4>Warning</h4>*/}
          <div className='text'>
            This experience contains racial slurs and descriptions of racially-motivated assault. Viewer discretion is advised.
          </div>
          <div className='continue'>
            Continue
          </div>
        </div>

        {/* <div style={{ position: 'absolute', bottom: 0, right: 0}}>{introStepIndex}</div> */}
      </div>
    )
  }
  return intro
}